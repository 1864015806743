import React, { Component } from "react";
import { withRouter } from "./withRouterHook";
import LandingPageHeader from "./LandingPageHeader";
import { ArrowLeftIcon} from "@heroicons/react/24/outline";
import teamsLink from "../images/teams_meeting_link.png";
import zoomLink from "../images/zoom_meeting_link.png";
import google_new from "../images/google_meet.png";
import google_existing from "../images/google_in_meeting.png";

class Teams extends Component {

  componentDidMount(){
    window.scrollTo(0, 0);
  }
  
  handleBackClick = () => {
    const {history}  = this.props;
    history.push("/dashboard/meetings");
  }

  render() {
    return(
      <div>
        <LandingPageHeader />
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-8 sm:py-12">
            <div className="text-center text-xl sm:text-2xl mb-6 sm:mb-8 text-indigo-600">How to get a Microsoft Teams meeting link</div>
            <img className="w-full max-w-xl mx-auto" src={teamsLink} alt="Teams meeting link instructions" />
            <div className="space-y-4 pt-6 sm:pt-8 text-sm sm:text-base max-w-xl mx-auto">
                <div className="">Once you have copied the link, paste it into Xylo.</div>
                <div className="">The format of the link will look like this: </div>
                <div className="text-black/60 break-all text-xs sm:text-sm">https://teams.microsoft.com/l/meetup-join/19%3ameeting_ODRmZGZjNWItZWYyZi00MTdhLWFlOTYtODU4Y2U2NmY4ZjQ4%40thread.v2/0?context=%7b%22Tid%22%3a%226e519a92-6691-4ee2-ac7a-dd475a24d906%22%2c%22Oid%22%3a%2225275e54-8777-48e6-a116-d0d03ec1dbe5%22%7d</div>
            </div>
          </div>

          <div className="py-8 sm:py-12">
            <div className="text-center text-xl sm:text-2xl mb-6 sm:mb-8 text-indigo-600">How to get a Zoom meeting link</div>
            <img className="w-full max-w-xl mx-auto" src={zoomLink} alt="Zoom meeting link instructions" />
            <div className="space-y-4 pt-6 sm:pt-8 text-sm sm:text-base max-w-xl mx-auto">
                <div className="">{`1) Go to the Meetings tab in Zoom`}</div>
                <div className="">{`2) Click on the "Show meeting invitation" link`}</div>
                <div className="">{`3) Copy the meeting link`}</div>
                <div className="">The format of the link will look like this: </div>
                <div className="text-black/60">https://us05web.zoom.us/j/2543789687?pwd=mvnebqThxBq7L6NvBXYz7q51MslCmi.1</div>
            </div>
          </div>

          <div className="py-8 sm:py-12">
            <div className="text-center text-xl sm:text-2xl my-6 sm:my-8 text-indigo-600">Google Meet link - Create a Meeting for Later</div>
            <img className="w-full max-w-md mx-auto" src={google_new} alt="Create Google Meet" />
            <div className="text-center text-xl sm:text-2xl my-6 sm:my-8 text-indigo-600">Google Meet link - Start an Instant Meeting</div>
            <img className="w-full max-w-sm mx-auto" src={google_existing} alt="Join Google Meet" />
            <div className="space-y-4 pt-6 sm:pt-8 text-sm sm:text-base pb-16 sm:pb-20 max-w-xl mx-auto">
                <div className="">There are 2 ways to get a link when you start an instant meeting</div>
                <div className="">{`1) Copy the link from the browser bar (pink arrow)`}</div>
                <div className="">{`2) Click on the info button and copy the joining info link (green arrow)`}</div>
                <div className="">The format of the link will be <span className="text-black/70">https://meet.google.com/tie-bicc-rzj</span></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Teams);
