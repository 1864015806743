import React, { Component } from "react";
import { withRouter } from "./withRouterHook";
import { PRICING_PAGE } from "../shared";
import PricingCards from "./PricingCards";


class PricingPage extends Component {
  state = { showContactModal: false };

  handleContactSales = () => {
    this.setState({ showContactModal: true });
  };

  handleModalClose = () => {
    this.setState({ showContactModal: false });
  };

  handlePricingPageTierClick = () => {
    const { history } = this.props;
    history.push("/login");
  };

  render() {
    const { showContactModal } = this.state;
    return (
      <PricingCards 
        handleModalClose={this.handleModalClose}
        handlePricingPageTierClick={this.handlePricingPageTierClick}
        handleContactSales={this.handleContactSales}
        type={PRICING_PAGE.PRICING} 
        showContactModal={showContactModal} 
        />
    );
  }
}

export default withRouter(PricingPage);
