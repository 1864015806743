import React, { Component } from "react";
import {  isEmpty } from "../shared";
import { withRouter } from "./withRouterHook";
import ReactPlayer from 'react-player';
import { MOCK_VIDEO } from "../utils";
import QuillEditor from "./QuillEditor/QuillEditor";
import { saveEditedTranscript } from "../service/CreateMeeting";
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { VideoCameraIcon  } from '@heroicons/react/24/outline';

class ReactVideoPlayer extends Component {
  state = {
    isLoadingSaveEdit: false,
    saveStatus: null,
    isVideoVisible: true
  };

  selectText = () => {
    const div = document.getElementById('myDiv');

    div.addEventListener('keydown', (e) => {
      if(e.ctrlKey && e.key === 'c') {
        e.preventDefault();
        
        // Select text in div
        const selection = window.getSelection();
        selection.removeAllRanges();
        const range = document.createRange();
        range.selectNodeContents(div);
        selection.addRange(range);
      }
    });
  }

  handleSaveEdits = async ({editedHtml, editedText}) => {
    this.setState({isLoadingSaveEdit: true});
    const { match } = this.props;
    const { meetingId } = match.params;
    const response = await saveEditedTranscript({ meetingId, editedHtmlTranscript: editedHtml, editedTextTranscript: editedText});
    if (response === "Success") {
      this.setState({isLoadingSaveEdit: false, saveStatus: "success"});
    }
    else {
      this.setState({isLoadingSaveEdit: false, saveStatus: "error"});
    }   
  }

  
  render() {
    const { transcript } = this.props;
    const {saveStatus, isLoadingSaveEdit, isVideoVisible} = this.state;
    const { videoUrl } = this.props;
    // const videoUrl = MOCK_VIDEO;
      return (
        <div className="flex flex-col h-[calc(100vh-theme(spacing.20)-theme(spacing.6)-theme(spacing.20))]">
          {/* Video section */}
         {!isEmpty(videoUrl) && <div className="relative pb-4">
          <button 
            onClick={() => this.setState(prev => ({ isVideoVisible: !prev.isVideoVisible }))}
            className="absolute left-0 top-0 z-10 pb-2 text-gray-600 hover:text-gray-800"
          >
          {
          <div className="flex items-center gap-x-2 text-gray-700 hover:text-gray-900 text-xs"> 
          <ChevronDownIcon 
          className={`w-4 h-4 transition-transform ${
            isVideoVisible ? 'transform rotate-180' : ''
          }`}
        /><span className="text-xs flex"><VideoCameraIcon className="w-4 h-4 mr-2" />{isVideoVisible ? 'Hide Video' : 'Show Video'}</span></div> 
          }
          </button>
          {!isEmpty(videoUrl) && isVideoVisible && (
            <ReactPlayer 
              className="mx-auto rounded-lg overflow-hidden" 
              url={videoUrl} 
              controls={true} 
              width="400px"
              height="225px"
              style={{borderRadius: "0.875rem"}}
            />
          )}
        </div>}
          {/* Transcript section - will fill remaining height */}
          <div className={`flex flex-col flex-1 min-h-0 ${!isEmpty(videoUrl) ? 'pt-4' : ''}`}>
            <div className="flex-1 min-h-0">
              <QuillEditor 
                value={transcript} 
                onSave={this.handleSaveEdits} 
                saveStatus={saveStatus} 
                isLoadingSaveEdit={isLoadingSaveEdit} 
                saveButtonId="saveTranscriptButton" 
                title="Transcript"
              />
            </div>
          </div>
        </div>
      );
  }
}

export default withRouter(ReactVideoPlayer);
