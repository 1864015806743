import React, { Component } from "react";
import { withRouter } from "./withRouterHook";
import LandingPageHeader from "./LandingPageHeader";
import { ArrowLeftIcon} from "@heroicons/react/24/outline";
import PrivacyHtml from "./PrivacyHtml";

class Privacy extends Component {

  componentDidMount(){
    window.scrollTo(0, 0);
  }
  
  handleBackClick = () => {
    const {history}  = this.props;
    history.push("/");
  }

  render() {
    return(
      <div>
        <LandingPageHeader />
       <div class="pt-6 sm:pt-12 mb-16 px-4 xl:px-24">
       <div><ArrowLeftIcon class="h-8 w-8 mb-8 cursor-pointer" onClick={this.handleBackClick} /></div>

        <PrivacyHtml /> 
        </div>
      </div>
    
    )
  }
}

export default withRouter(Privacy);
