import React, { KeyboardEventHandler } from 'react';

import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};


const createOption = (label) => ({
  label,
  value: label,
});

const CreatableInput = ({ onVocabChange }) => {
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState([]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    const newValue = [...value, createOption(inputValue)];
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue(newValue);
        setInputValue('');
        event.preventDefault();
        onVocabChange(newValue); 
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue || []);
    if (onVocabChange) {
      onVocabChange(newValue || []);
    }
  };

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder="Type a word and press enter..."
      value={value}
    />
  );
};

export default CreatableInput;