import { Heading } from "@aws-amplify/ui-react";

export function CreateAccountHeader() {
  return (
    <Heading level={4} padding={`2.5rem 2rem 0 2rem`}>
      Create your Account
      <div class="text-xs font-black opacity-60 pt-4">Password length should be 6 characters minimum.</div>
      <div class="text-xs font-black opacity-60 pt-4">(Please check your junk folder for the activation code)</div>

    </Heading>
  );
}
