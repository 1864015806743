const BASE_API_GATEWAY_URL =
  "https://ocptgaowqc.execute-api.eu-west-1.amazonaws.com/Prod";
export const awsExports = {
  REGION: "eu-west-1",
  //The access keys from the Cognito user pool (Audio Pool) to sign up as a user
  USER_POOL_ID: "eu-west-1_lkAwI3ZlL",
  USER_POOL_APP_CLIENT_ID: "7ig7v3p1noh7ngf51i999gh67t",
  //The access keys from the Cognito user pool the IAM user that has access to the S3 File
  ACCESS_KEY_ID: "AKIA6FPSTJIKXGBTHFGC",
  SECRET_ACCESS_KEY: "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",
  //S3 URL Base
  AUDIO_APP_BUCKET_PREFIX: "https://audioappbucket.s3.eu-west-1.amazonaws.com",
  // API Gateway URLs
  PROCESS_SURVEY_RESPONSE_URL: `${BASE_API_GATEWAY_URL}/process-survey-response`,
  NEW_SURVEY_URL: `${BASE_API_GATEWAY_URL}/new-survey`,
  EDIT_SURVEY_URL: `${BASE_API_GATEWAY_URL}/edit-survey`,
  SURVEYS_URL: `${BASE_API_GATEWAY_URL}/surveys`,
  SURVEY_INSIGHTS_URL: `${BASE_API_GATEWAY_URL}/survey-insights`,
  USERS_URL: `${BASE_API_GATEWAY_URL}/users`,
  PROMPT_URL: `${BASE_API_GATEWAY_URL}/prompts`,
  MEETINGS : `${BASE_API_GATEWAY_URL}/meetings`,
};
