import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { withRouter } from "./withRouterHook.js";
import scheduled from "../images/scheduled.png";
import analysedCall from "../images/analysedCall.png";
import LandingPageHeader from "./LandingPageHeader.js";


class Onboarding extends React.Component {

  handleBackClick = () => {
    const {history}  = this.props;
    history.push("/dashboard/meetings");
  }

  render() {

    return (
      <div>
       <LandingPageHeader />
       <div className="px-4 sm:px-8 md:px-20 pt-6 sm:pt-12">   
        <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center gap-4">
          <ArrowLeftIcon className="h-8 w-8 cursor-pointer" onClick={this.handleBackClick} />
          <div className="font-manrope text-2xl md:text-3xl text-center flex-1 text-indigo-600">How to set up a meeting with Xylo</div>
          <div className="hidden sm:block" />
        </div>
        
        <div className="px-0 sm:px-8 md:px-12 mt-8 max-w-2xl mx-auto">
          <div className="pb-8">{`1) Sign up `}<a href="https://www.helloxylo.com/login" target="_blank" className="underline text-indigo-600">here</a><span>{` and create a free account. The activation code may get sent to your Junk folder so please check there`}</span></div>
          <div className="pb-8">2) Go to your meetings dashboard and set up a new meeting as shown below. Here is how to <a href="#" onClick={() => this.props.history.push("/get-meeting-link")} className="underline text-indigo-600">get your meeting link</a> to paste in.</div>  
        </div>
        
        <div className="px-0 sm:px-8 md:px-12 pb-12">
          <div style={{
            position: 'relative',
            paddingBottom: 'calc(58.46527777777778% + 42px)',
            height: 0
            }}><iframe src="https://app.supademo.com/embed/clxx99i2l156k12o2hf826le0" title="Supademo Demo" allow="clipboard-write" frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe></div>
        </div>

        <div className="px-0 sm:px-8 md:px-12 pb-24 sm:pb-36">
          <div className="pb-8 max-w-2xl mx-auto">{`3) Once you have scheduled your meeting, it will appear with a `}<span className="text-amber-600">"Scheduled "</span><span>status in the dashboard</span></div>
          <div className=" pb-8 max-w-2xl mx-auto">{`4) When you are in the meeting, you must admit Xylo to join from the waiting room. It will be called "Meeting Notetaker (Guest)" `}</div>
          <div className=" pb-8 max-w-2xl mx-auto">{`5) Once Xylo is successfully on the call, it will send you a message in the chat saying "Xylo has joined the call and is now recording"`}</div>
          <div className="pb-8 max-w-2xl mx-auto">{`6) When the meeting & analysis is complete, the meeting will appear with `}<span className="text-emerald-600">"View Details"</span><span>status in the dashboard. Click on it to access the video, transcripts and analysis.</span></div>
          <div className="mt-6 sm:mt-14 pb-4 text-center text-black/60">Your scheduled and analysed meetings will appear in the dashboard</div>
          <img className="w-full max-w-[600px] mx-auto border border-indigo-500 rounded-xl" src={scheduled} alt="Scheduled meetings dashboard" />
          <div className="mt-6 sm:mt-14 pb-4 text-center text-black/60">Once you click on View Details, you can generate an analysis</div>
          <img className="w-full max-w-[800px] mx-auto border border-indigo-500 rounded-xl" src={analysedCall} alt="Analysed call view" />
          <div className="py-8 text-sm sm:text-base max-w-2xl mx-auto">{`To delete your account and all data associated with it (including your email & all meeting data) please email amrit@helloxylo.com and provide your account email with a request to delete your account.`}</div>
        </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Onboarding);
