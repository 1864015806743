import { isEmpty } from "./shared";
import moment from 'moment-timezone';

export const getTimeAgo = ({ dateString }) => {
  try {
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = currentDate - date;

    const day = String(date.getDate()).padStart(2, "0"); // Get day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month with leading zero (months are zero-based)
    const year = date.getFullYear();

    const minuteInMs = 60 * 1000;
    const hourInMs = 60 * minuteInMs;
    const dayInMs = 24 * hourInMs;
    const monthInMs = 30 * dayInMs;
    const yearInMs = 365 * dayInMs;

    if (timeDiff < minuteInMs) {
      return "a few seconds ago";
    } else if (timeDiff < hourInMs) {
      const minutes = Math.floor(timeDiff / minuteInMs);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (timeDiff < dayInMs) {
      const hours = Math.floor(timeDiff / hourInMs);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (timeDiff < monthInMs) {
      return `${day}/${month}/${year}`;
    } else if (timeDiff < yearInMs) {
      return `${day}/${month}/${year}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  } catch (err) {
    console.log("Error getting timeAgo", err);
    return null;
  }
};

export const camelCaseToReadable = ({text}) => {
  // Split the camelCase text into words
  const words = text.replace(/([a-z])([A-Z])/g, '$1 $2').split(/[_\s]/);

  
  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  
  // Join the words into a sentence
  return capitalizedWords.join(' ');
}

export const getSpeakerIconColour = ({speaker}) => {
  switch(speaker) {
    case "A": return "bg-blue-600";
    case "B": return "bg-amber-500";
    case "C": return "bg-fuchsia-500";
    case "D": return "bg-lime-500";
    case "E": return "bg-red-500";
    default: return "bg-indigo-600";
  }
}

export const getFormattedMeetingDateTime = ({meetingTime}) => {
  try {
    const date = new Date(meetingTime);
    // Create formatter
      const formatter = new Intl.DateTimeFormat(
        "en-US", 
        {
          year: "numeric",
          month: "short", 
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        }
      );
      return formatter.format(date);
  }
  catch(err) {
    console.log("err formatting meeting date time", err);
    return meetingTime;
  }

}

export const getStatusColour = ({status}) => {
  if(status === "Processed") {
    return "text-emerald-600";
  }
  else if (status === "Error - Unable to join call" || status === "Error - Analysis Failed"){
    return "text-red-700";
  }
  else {
    return "text-amber-600";
  }
}

export const processIntelligence = ({intelligence}) => {
  if (isEmpty(intelligence)) return {};
  try {
    const parsedIntelligence = JSON.parse(intelligence);
    // Define all possible keys
    const allKeys = [
      "summary", "personalInformation", "companyInformation", "resolutionOfAccounts", "profitAndLossAccount", "sales",
      "costOfSales", "grossProfit", "overheads", "netProfit", "balanceSheet",
      "assets", "liabilities", "cashPosition", "directorsLoanAccount",
      "shareholderFunds", "ownersReserves", "drawings", "dividends", "corporateTax",
      "personalTax", "issuesAndConcerns", "accountantRecommendations",
      "serviceReview", "newServicePlan", "fees", "referralOpportunities",
      "nextSteps", "managementAccountsReview", "kpis", "budgetsAndForecasts", "goals",
      "goalsThisYear", "goalsNextYear", "goalsUltimate", "targetsAndActivitiesThisYear",
      "targetsAndActivitiesNextYear", "targetsAndActivitiesUltimate", "risks", "vision", "motivation", "currentPersonalDescription",
      "currentBusinessDescription", "strengthsAndOpportunities",
      "weaknessesAndThreats", "nextMeeting", "taxPlanningOpportunities",
      "income", "liquidAssets", "illiquidAssets", "currentExpenses", "investments", "investmentStyle", "riskProfile", "debts", "progressOnPriorGoals", "newGoalsAndObjectives", "financialPlanModifications", "personalUpdates", "cashFlow",
      "retirementGoals",
      "financialGoals",
      "taxes",
      "motivationsAndConcerns",
      "advisorRecommendations"
    ];

    // Process each key
    const processedIntelligence = allKeys.reduce((acc, key) => {
      if (key in parsedIntelligence) {
        acc[key] = key === "summary" ? parsedIntelligence[key] : generateHTMLList(parsedIntelligence[key]);
      } else {
        acc[key] = key === "summary" ? "" : generateHTMLList([]);
      }
      return acc;
    }, {});

    return processedIntelligence;
  } catch (err) {
    console.log("Error processing intelligence result ==>", err, intelligence);
    return {};
  }
};

const generateHTMLList = (strings)  => {
  if (!Array.isArray(strings)) {
      return ''; // If strings is not an array, return an empty string
  }
  if(isEmpty(strings)){
    return null;
  }
  const listItems = strings.map(str => `<li> • ${str}</li>`).join('');
  return `<ul>${listItems}</ul>`;
}

export const ANALYSIS_ITEMS = [
 "summary",
  "personalInformation",
  "companyInformation",
  "resolutionOfAccounts",
  "profitAndLossAccount",
  "sales",
  "costOfSales",
  "grossProfit",
  "overheads",
  "netProfit",
  "balanceSheet",
  "assets",
  "liabilities",
  "cashPosition",
  "directorsLoanAccount",
  "shareholderFunds",
  "ownersReserves",
  "drawings",
  "dividends",
  "corporateTax",
  "personalTax",
  "issuesAndConcerns",
  "accountantRecommendations",
  "serviceReview",
  "newServicePlan",
  "fees",
  "referralOpportunities",
  "nextSteps",
  "managementAccountsReview",
  "kpis",
  "budgetsAndForecasts",
  "goals",
  "goalsThisYear",
  "goalsNextYear",
  "goalsUltimate",
  "risks",
  "vision",
  "motivation",
  "currentPersonalDescription",
  "currentBusinessDescription",
  "strengthsAndOpportunities",
  "weaknessesAndThreats",
  "targetsAndActivitiesThisYear",
  "targetsAndActivitiesNextYear",
  "targetsAndActivitiesUltimate",
  "taxPlanningOpportunities",
  "nextMeeting",
  "followupEmail",
  "income",
  "liquidAssets",
  "illiquidAssets",
  "currentExpenses",
  "investments",
  "investmentStyle",
  "riskProfile",
  "debts",
  "progressOnPriorGoals",
  "newGoalsAndObjectives",
  "financialPlanModifications",
  "personalUpdates",
  "cashFlow",
  "retirementGoals",
  "financialGoals",
  "taxes",
  "motivationsAndConcerns",
  "advisorRecommendations"
]

export const ANALYSIS_ITEMS_TITLE = {
  summary: "Meeting Summary",
  personalInformation: "Personal Information",
  companyInformation: "Company Information",
  resolutionOfAccounts: "Resolution of Accounts",
  profitAndLossAccount: "Profit and Loss Account",
  sales: "Sales",
  costOfSales: "Cost of Sales",
  grossProfit: "Gross Profit",
  overheads: "Overheads",
  netProfit: "Net Profit",
  balanceSheet: "Balance Sheet",
  assets: "Assets",
  liabilities: "Liabilities",
  cashPosition: "Cash Position",
  directorsLoanAccount: "Directors Loan Account",
  shareholderFunds: "Shareholder Funds",
  ownersReserves: "Owner's Reserves",
  drawings: "Drawings",
  dividends: "Dividends",
  corporateTax: "Corporate Tax",
  personalTax: "Personal Tax",
  issuesAndConcerns: "Issues and Concerns",
  accountantRecommendations: "Accountant Recommendations",
  serviceReview: "Service Review",
  newServicePlan: "New Service Plan",
  fees: "Fees",
  referralOpportunities: "Referral Opportunities",
  nextSteps: "Next Steps",
  managementAccountsReview: "Management Accounts Review",
  kpis: "KPIs",
  budgetsAndForecasts: "Budgets and Forecasts",
  goals: "Goals",
  goalsThisYear: "Goals This Year",
  goalsNextYear: "Goals Next Year",
  goalsUltimate: "Goals Ultimate",
  risks: "Risks",
  vision: "Vision",
  motivation: "Motivation",
  currentPersonalDescription: "Current Personal Description",
  currentBusinessDescription: "Current Business Description",
  strengthsAndOpportunities: "Strengths and Opportunities",
  weaknessesAndThreats: "Weaknesses and Threats",
  targetsAndActivitiesThisYear: "Targets and Activities This Year",
  targetsAndActivitiesNextYear: "Targets and Activities Next Year",
  targetsAndActivitiesUltimate: "Targets and Activities Ultimate",
  taxPlanningOpportunities: "Tax Planning Opportunities",
  nextMeeting: "Next Meeting",
  followupEmail: "Follow Up Email",
  income: "Income",
  liquidAssets: "Liquid Assets",
  illiquidAssets: "Illiquid Assets",
  currentExpenses: "Current Expenses",
  investments: "Investments",
  investmentStyle: "Investment Style",
  riskProfile: "Risk Profile",
  debts: "Debts",
  progressOnPriorGoals: "Progress on Prior Goals",
  newGoalsAndObjectives: "New Goals and Objectives",
  financialPlanModifications: "Financial Plan Modifications",
  personalUpdates: "Personal Updates",
  cashFlow: "Cash Flow",
  retirementGoals: "Retirement Goals",
  financialGoals: "Financial Goals",
  taxes: "Taxes",
  motivationsAndConcerns: "Motivations and Concerns",
  advisorRecommendations: "Advisor Recommendations"
}

export const TEMPLATES = {
  "prospect" : [
    "• Summary",
    "• Personal Info",
    "• Income",
    "• Liabilities",
    "• Current Expenses",
    "• Retirement Goals",
    "• Financial Goals",
    "• Investments",
    "• Investment Style",
    "• Risk Profile",
    "• Debts",
    "• Taxes",
    "• Fees",
    "• Motivations And Concerns",
    "• Advisor Recommendations",
    "• Next Steps"
  ],
  "discovery": [
    "• Summary",
    "• Personal Info",
    "• Income",
    "• Liquid Assets",
    "• Illiquid Assets",
    "• Liabilities",
    "• Current Expenses",
    "• Retirement Goals",
    "• Financial Goals",
    "• Investments",
    "• Investment Style",
    "• Risk Profile",
    "• Debts",
    "• Taxes",
    "• Fees",
    "• Progress On Prior Goals",
    "• New Goals And Objectives",
    "• Financial Plan Modifications",
    "• Motivations And Concerns",
    "• Advisor Recommendations",
    "• Next Steps"
  ],
  "review" : [
    "• Summary",
    "• Personal Info",
    "• Personal Updates",
    "• Cash Flow",
    "• Income",
    "• Liquid Assets",
    "• Illiquid Assets",
    "• Liabilities",
    "• Current Expenses",
    "• Retirement Goals",
    "• Financial Goals",
    "• Investments",
    "• Investment Style",
    "• Risk Profile",
    "• Debts",
    "• Taxes",
    "• Fees",
    "• Progress On Prior Goals",
    "• New Goals And Objectives",
    "• Financial Plan Modifications",
    "• Motivations And Concerns",
    "• Advisor Recommendations",
    "• Next Steps"
  ],
  // "property":  [
  //   "Summary",
  //   "Personal Info",
  //   "Current Property",
  //   "Mortgage",
  //   "Superannuation",
  //   "Retirement",
  //   "Tax",
  //   "Risk Profile",
  //   "Risk Management",
  //   "Portfolio Information",
  //   "Debts",
  //   "Next Steps"
  // ],
  // "firstTimeHomeBuyer":  [
  //    "Summary",
  //   "Personal Info",
  //   "Financial Background",
  //   "Current Property",
  //   "Homeownership Goals",
  //   "Risk Profile",
  //   "Pre approval process",
  //   "Mortgage Options",
  //   "Budgeting",
  //   "Next Steps"
  // ],
  "annualAccountsLimited": [
    "• Personal information",
    "• Company information",
    "• Resolution of accounts",
    "• Profit and loss account",
    "                • Sales",
    "                • Cost of sales",
    "                • Gross profit",
    "                • Overheads",
    "• Net profit",
    "• Balance sheet",
    "             • Assets",
    "             • Liabilities",
    "             • Cash position",
    "             • Directors loan account",
    "• Shareholder funds",
    "• Dividends",
    "• Corporate tax",
    "• Personal tax",
    "• Issues and concerns",
    "• Tax planning opportunities",
    "• Accountant recommendations",
    "• Service review",
    "• New service plan",
    "• Fees",
    "• Referral opportunities",
    "• Next steps"
  ],
  "annualAccountsSoleTrader": [
    "• Personal information",
    "• Company information",
    "• Resolution of accounts",
    "• Profit and loss account",
    "                • Sales",
    "                • Cost of sales",
    "                • Gross profit",
    "                • Overheads",
    "• Net profit",
    "• Balance sheet",
    "                • Assets",
    "                • Liabilities",
    "                • Cash position",
    "• Owners reserves",
    "• Drawings",
    "• Personal tax",
    "• Issues and concerns",
    "• Tax planning opportunities",
    "• Accountant recommendations",
    "• Service review",
    "• New service plan",
    "• Fees",
    "• Referral opportunities",
    "• Next steps"
  ],
  "quarterlyBusinessReview": [
    "• Personal information",
    "• Company information",
    "• Management accounts review",
    "• KPIs",
    "• Cash position",
    "• Budgets and forecasts",
    "• Goals",
    "• Risks",
    "• Issues and concerns",
    "• Accountant recommendations",
    "• New service plan",
    "• Fees",
    "• Referral opportunities",
    "• Next steps"
  ],
  "changeManagement": [
    "• Personal information",
    "• Company information",
    "• Vision",
    "• Motivation",
    "• Current personal description",
    "• Current business description",
    "• Strengths and opportunities",
    "• Weaknesses and threats",
    "• Goals : This year",
    "• Goals : Next year",
    "• Goals : Ultimate",
    "• Targets and activities : This year",
    "• Targets and activities : Next year",
    "• Targets and activities : Ultimate",
    "• Issues and concerns",
    "• Accountant recommendations",
    "• New service plan",
    "• Fees",
    "• Referral opportunities",
    "• Next steps",
    "• Next meeting"
  ]
};



export const generateHTML = ({intelligence}) => {
  if(isEmpty(intelligence)) return "";
  
  try {
    const data = JSON.parse(intelligence);
    let html = '<div class="ql-editor">';  // Wrap in ql-editor div
    
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if((typeof value === "string" && value !== "") || (Array.isArray(value) && value.length > 0 )) {
          // Use h2 for titles with Quill formatting
          html += `<br/><h4 style="color: rgb(67, 56, 202); font-weight: bold; padding-left: 8px; margin-top: 16px;">${ANALYSIS_ITEMS_TITLE[key]}</h2>`;
        }
        
        if (Array.isArray(value)) {
          if (value.length > 0) {
            html += '<ul style="padding-left: 8px; padding-bottom: 24px; font-size: 14px;">';
            value.forEach(item => {
              html += `<li>${item}</li>`;  // Use proper list elements
            });
            html += '</ul>';
          }
        } else if (typeof value === "object") {
          html += `<br/><h4 style="color: rgb(67, 56, 202); font-weight: bold; padding-left: 8px; margin-top: 16px;">${ANALYSIS_ITEMS_TITLE[key]}</h4>`;
          html += '<ul style="padding-left: 8px; padding-bottom: 24px; font-size: 14px;">';
          for (const subKey in value) {
            if (value.hasOwnProperty(subKey)) {
              html += `<li><strong>${ANALYSIS_ITEMS_TITLE[subKey]}:</strong> ${Array.isArray(value[subKey]) ? value[subKey].join(', ') : value[subKey]}</li>`;
            }
          }
          html += '</ul>';
        } else {
          html += `<p style="padding-left: 8px; padding-bottom: 24px; font-size: 14px;">${value}</p>`;
        }
      }
    }
    
    html += '</div>';  // Close ql-editor div
    return html;
  }
  catch(err) {
    console.log("ERROR parsing intelligence JSON ==>", err, "JSON ==>", intelligence);
    return "";
  }
}

export const formatTranscriptForQuill = ({transcript}) => {
  // Group consecutive entries by speaker
  const groupedBySpeaker = transcript.reduce((acc, current) => {
    if (acc.length === 0 || acc[acc.length - 1].speaker !== current.speaker) {
      acc.push({
        speaker: current.speaker,
        text: current.words.map(word => word.text).join(' ')
      });
    } else {
      // Combine words with the last entry of the same speaker
      acc[acc.length - 1].text += ' ' + current.words.map(word => word.text).join(' ');
    }
    return acc;
  }, []);

  // Create the formatted string with HTML tags
  const formattedContent = groupedBySpeaker
    .map(entry => (
      `<p><strong>Speaker ${entry.speaker}</strong></p><p>${entry.text}</p><p><br></p>`
    ))
    .join('');

  return formattedContent;
};

export const getStringTranscript = ({transcript}) => {
  console.log("getting string transcript", transcript);
  if(isEmpty(transcript) || !Array.isArray(transcript)) return "";
  let html = '';
  for (const key in transcript) {
    if (transcript.hasOwnProperty(key)) {
      const value = transcript[key];
      const {words} = value;
      if (Array.isArray(words)) {
        if (words.length > 0) {
          html += `<div class="pt-8 pb-4 text-black/80 font-jakarta text-xs"><div class="text-xs mb-1 text-indigo-800 text-opacity-70">Speaker ${value.speaker}</div>`;
          words.forEach(item => {
            html += `${item.text}${" "}`;
          });
          html += '</div>';
        } else {
          html += '<p class="pb-4">No items</p>';
        }
      } else {
        html += `<p class="pb-4">${words}</p>`;
      }
    }
  }
  console.log("HTML transcript", html);
  return html;
};

export const getMomentTz = (dateString) => {
// Detect the user's time zone
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
// Parse the date string and convert to the user's time zone
const momentDate = moment.tz(dateString, userTimeZone);
// Convert to ISO string while retaining the time zone information
return momentDate.format();
}

export const getTimezoneInLetters = (dateString) => {
  if(!dateString) return "";
  try {
    const date = new Date(dateString);

    // Create a formatter for the time zone name
    const timeZoneNameFormatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' });
    const timeZoneName = timeZoneNameFormatter.formatToParts(date).find(part => part.type === 'timeZoneName').value;
    
    // Create a formatter for the GMT offset
    // const gmtOffsetFormatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' });
    // const gmtOffset = gmtOffsetFormatter.formatToParts(date).find(part => part.type === 'timeZoneName').value;
    
    // Combine the parts to form the desired string
    const result = `${timeZoneName}`;
    
    return result;
  }
  catch(err) {
    console.log("error converting time zone to letters =>", err);
    return "";
  }

}

export const getParsedEmail = ({email}) => {
  if(typeof email === "Object") {
    console.log("Email is an object =>", email);
    return null;
  }
  if(!email) {
    return null;
  }
  try {
    return email;
  }
  catch(err) {
    console.log("Error parsing email ==>", err, "Email =>", email);
    return null;
  }
}


export const getMomentTzMinusScheduleTime = (dateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const momentDate = moment.tz(dateString, userTimeZone);
    momentDate.subtract(5, 'minutes');
    return momentDate.format();
}

export const MOCK_VIDEO = "https://us-west-2-recallai-production-bot-data.s3.amazonaws.com/491c0eb9-d066-41d1-98f4-84b32efce4af/AROA3Z2PRSQANGTUQXHNJ%3Ai-0b48f6e5d5076704e/video-da00abea-453f-4f66-be3f-197abc7693a5.mp4?AWSAccessKeyId=ASIA3Z2PRSQAB2FPVGVB&Signature=OxvT5VVqJj6LJRPitYacQGMKG7I%3D&x-amz-security-token=IQoJb3JpZ2luX2VjELP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJIMEYCIQCT0knr1EP7sg63N9jpsNDsWnBtjEPUBMSx7PIJsZxu8wIhAIS6bl0HlHGwX6wRHrcEKf2IgyQdUxEEr96B0HDQJ4tLKrsFCDwQABoMODExMzc4Nzc1MDQwIgzOtkag0b%2FV5pv2oMMqmAUa6d13WMySY0t3YxEPdmZ5CVCc7bUaEyeMAb966ovi5J8cNqzg8%2FkFC9OvS2e2ZvCM8qf2EDFBPdw9E5Wu1ix5e%2FAK1Bq0kH6YvIKFegBuxYNERxwHQhrZawUsXI4882%2FWiAH8JWXYH75R8rG6PNbfziDlGe7fN5Pq2FiXgxvG3bYQLsvCuw4OE8RdyzKRwdXMrfpQl3e4fwIGIaBxglWLVuifja2BHBHKaLnN5MGgbIJ%2BcNG0Bbyr4SmYBnFzaRuLl7eU%2BRnPJdIS0M%2BVNp%2B5A1HusB6c%2BO3ljysX56x%2Bz1g1UOopu%2BipJCsBD%2BLoocYfBax5vhPpUH5eu9zt71OCLvOphOqcUvKgq7O9Aj%2BB8Iu8E5XeLr%2BegjGPOLj%2BfcsT9npQYM7tRC4d12T6Edta53HFtbBhZ2HCeXcKBDZEVkLVUXR5LK7J%2B1YU42TuX2DPp9sEDDBmgZB6UM9vtZ5xoXuWUV78hNrC7Hi0C8sQlBYe8GaU4rkx2OoHBfVtI5yUgnHYpq6oAs5F9rBAlxKrjUN1Shg2SCRz3KctPvOi5QjgzpOZwy9ppoLKQwAtpY2L2dYmZZrTFafNHgoEC3Lg911xo2bq1qhKQMg0Ti2aZQ5SkmPXipHs4Vlk6YF41fAujTb54hNkVqLoF1FPLS94pf4vw%2BqINrzbdYfmqLJF7aS9FF0sdovMgeWyZrep0r%2FH1ZOWOYBoQeaH6Az3o36myiGVZ9Y6TDkIPR00bizmJqkGxB0jd%2FbxBKl6vGj79DNkdHHY66SbrB7YAgIasnTo5Ym3%2FsKAkc5bszHU3IaRVFvOpZjqhj7L4r%2BiOV1cLVJQE%2F%2BZ2g70bD3XWlUfcddAJTvKZ7NxM5R7%2BgTb3hcUZBF%2F1PvqSilnMOHIsLkGOrAB7HVihw3RoPv%2Bp4GKlo4l8AEpbnKnMLFLL1h8UYH844h2qYpC2Q4FzT2uwl0Ax5KHnpnNRX8R8eiiReBMzUEuUL7IlJgikjXYsX6LoWSuskJtmNtUMZTIwYErhZJJcBiT95v1%2BMjxHwsKVdgpKbKa7QNnRnlmmL0L7m224Fns0Ku%2B2jICdpViSHnLklEDF0y8MTlONUiS67FVZbXs1OxQ5M6PJ%2Fb8N6K9Vlfd6aM%2Bx3c%3D&Expires=1730978322";

export const MOCK_EMAIL = `Subject: Follow-up: Financial Review Meeting & Action Items for Redwood Pharmacy

Dear [CLIENT_NAME],

Thank you for our productive meeting today regarding Redwood Pharmacy's financial performance. I'm writing to confirm the key points discussed and outline our next steps.

The key points we covered were:
• Prescription sales revenue of £1.2 million for the current year
• Current dispensing margin standing at £850,000
• Total pharmacy assets of £800,000 with a strong cash position of £500,000
• NHS contract compliance costs at £3,000, with potential for optimization in locum expenses
• Medicine procurement costs higher than expected at £20,000

Actions agreed upon:
• Review strategies to reduce wholesale purchasing costs and improve generic substitution rates
• Analyze potential areas for locum cost optimization, particularly during peak hours
• Set up automated prescription reordering system for regular patients
• Evaluate current MUR (Medicine Use Review) targets (3%) and NMS (New Medicine Service) uptake (4%)

Next steps:
I will:
• Prepare analysis on pharmacy procurement strategies for our next meeting
• Send you instructions for setting up the patient reordering system
• Reach out to your independent prescriber contact regarding collaboration services

You will:
• Forward the contact details for your prescriber contact
• Review the automated prescription system instructions once received

We have scheduled our next meeting for Tuesday at 3pm to discuss these matters in detail.

Please don't hesitate to contact me if you need any clarification or have questions before our next meeting.

Best regards,
[ACCOUNTANT_NAME]`;

export const MOCK_MEETING_NOTES = `{
  "summary": "Annual accounts meeting for Redwood Pharmacy, discussing financial performance including £1.2M in prescription sales, £850K dispensing margin, and strong cash position of £500K. Discussion covered NHS contract compliance, medicine procurement costs, and operational optimization.",
  "personalInformation": [
    "John recently moved to a new house in Bristol",
    "Eldest daughter starting university next year",
    "Planning extended holiday to Italy in December"
  ],
  "companyInformation": [
    "Trading as Redwood Pharmacy - independent pharmacy business",
    "NHS contract holder since 2015",
    "John is a qualified pharmacist with 10 years experience",
    "5 employees including 3 full-time staff and 2 locum pharmacists",
    "Annual turnover £1.2 million from prescription services"
  ],
  "resolutionOfAccounts": [
    "Need clarification on Q3 wholesale supplier invoices",
    "Reconciliation needed for locum payment discrepancies",
    "Outstanding queries on NHS reimbursement rates",
    "Clarification needed on capital allowances for new equipment"
  ],
  "profitAndLossAccount": {
    "sales": [
      "Prescription revenue reached £1.2 million",
      "MUR service revenue at 3% of total",
      "NHS contract services performing well"
    ],
    "costOfSales": [
      "Medicine procurement costs higher than expected at £20,000",
      "Wholesale purchasing needs optimization",
      "Generic substitution rates need improvement"
    ],
    "grossProfit": [
      "Dispensing margin stands at £850,000",
      "Margin percentage at 70.8%",
      "Above industry average performance"
    ],
    "overheads": [
      "NHS contract compliance costs at £3,000 annually",
      "Locum expenses identified as area for improvement",
      "Utilities and maintenance costs stable"
    ]
  },
  "netProfit": [
    "Total net profit £750,000",
    "15% increase from previous year",
    "Exceeding projected targets"
  ],
  "balanceSheet": {
    "assets": [
      "Total pharmacy assets at £800,000",
      "Includes premises, equipment, and stock",
      "Significant investment in dispensing equipment"
    ],
    "liabilities": [
      "Wholesale supplier payments pending",
      "Staff payroll obligations",
      "NHS contract commitments"
    ],
    "cashPosition": [
      "Cash position at £500,000",
      "Strong liquidity ratio",
      "Adequate working capital"
    ],
    "directorsLoanAccount": [
      "Current balance £50,000",
      "Regular monthly repayments",
      "Interest rate at 2.5%"
    ]
  },
  "shareholderFunds": [
    "Total shareholder equity £600,000",
    "Retained earnings £400,000",
    "Strong capital position"
  ],
  "dividends": [
    "Quarterly dividend payments",
    "Last dividend paid £20,000",
    "Sustainable dividend policy"
  ],
  "corporateTax": [
    "Current year provision £140,000",
    "Tax planning strategies in place",
    "Utilizing available reliefs"
  ],
  "personalTax": [
    "Self-employed pharmacist status",
    "Current tax rate at higher band",
    "Pension contributions optimized"
  ],
  "issuesAndConcerns": [
    "Higher than expected medicine procurement costs",
    "Need to optimize locum coverage during peak hours",
    "MUR targets currently at 3% - below industry average",
    "NMS uptake at 4% - needs improvement"
  ],
  "taxPlanningOpportunities": [
    "R&D tax credits available for pharmacy management software",
    "Capital allowances on new dispensing equipment",
    "Pension contribution increase opportunities",
    "Annual investment allowance for shop refit"
  ],
  "accountantRecommendations": [
    "Implement automated stock management system",
    "Review supplier contracts quarterly",
    "Consider additional NHS services"
  ],
  "serviceReview": [
    "Current service level meeting expectations",
    "Monthly management accounts delivered on time",
    "Tax planning advice provided regularly"
  ],
  "newServicePlan": [
    "Quarterly business performance reviews",
    "Enhanced cash flow forecasting",
    "Strategic growth planning sessions"
  ],
  "fees": [
    "Current monthly fee £500",
    "Additional services quoted separately",
    "Annual review included"
  ],
  "referralOpportunities": [
    "Independent prescriber contact interested in collaboration",
    "Local GP surgery seeking pharmacy services partner",
    "Potential for additional NHS services"
  ],
  "nextSteps": [
    "Follow-up meeting scheduled for Tuesday at 3pm",
    "Set up automated prescription reordering system",
    "Review wholesale purchasing strategies",
    "Optimize locum scheduling"
  ]
}`;

export const MOCK_MEETING_NOTES_DISCOVERY = `{
  "summary": "Comprehensive financial planning discovery meeting with Sarah Johnson, discussing current financial position, retirement goals, and investment strategy. Key focus on building long-term wealth and ensuring adequate retirement savings.",
  "personalInformation": [
    "Sarah Johnson, age 42, software engineer",
    "Married with two children (ages 8 and 10)",
    "Currently living in Boston area",
    "Spouse works as a healthcare administrator"
  ],
  "income": [
    "Annual salary: $175,000",
    "Spouse's annual salary: $95,000",
    "Annual bonus potential: up to 20% of base salary",
    "Side consulting income: $15,000 annually"
  ],
  "liquidAssets": [
    "Emergency fund: $50,000 in high-yield savings",
    "Checking accounts: $15,000",
    "Money market accounts: $75,000",
    "Certificates of deposit: $25,000"
  ],
  "illiquidAssets": [
    "Primary residence valued at $750,000",
    "Vacation property valued at $300,000",
    "Company stock options (vested): $100,000",
    "Art collection: $25,000"
  ],
  "liabilities": [
    "Mortgage balance: $450,000 at 3.25%",
    "Car loan: $25,000 at 4.5%",
    "Student loans: $30,000 at 5%",
    "Credit card debt: $5,000"
  ],
  "currentExpenses": [
    "Monthly mortgage payment: $2,800",
    "Utilities and home maintenance: $800",
    "Car payments and transportation: $750",
    "Children's education: $1,500",
    "Insurance premiums: $600"
  ],
  "retirementGoals": [
    "Desired retirement age: 60",
    "Expected retirement lifestyle expenses: $120,000/year",
    "Plan to maintain vacation home in retirement",
    "Interest in part-time consulting during retirement"
  ],
  "financialGoals": [
    "Build college fund for children",
    "Pay off mortgage before retirement",
    "Purchase investment property within 5 years",
    "Increase emergency fund to cover 1 year of expenses"
  ],
  "investments": [
    "401(k) balance: $425,000",
    "Roth IRA: $125,000",
    "Brokerage account: $200,000",
    "529 college savings: $75,000"
  ],
  "investmentStyle": [
    "Currently moderate growth oriented",
    "Interest in sustainable/ESG investing",
    "Prefers mix of active and passive strategies",
    "Some experience with individual stock picking"
  ],
  "riskProfile": [
    "Moderate to aggressive risk tolerance",
    "Comfortable with market volatility",
    "Seeks 70/30 equity to fixed income split",
    "Interest in international exposure"
  ],
  "debts": [
    "Total debt: $510,000",
    "All payments current",
    "No high-interest consumer debt",
    "Strategic debt reduction plan needed"
  ],
  "taxes": [
    "Current tax bracket: 32%",
    "Maximizing 401(k) contributions",
    "Need tax strategy for stock options",
    "Interest in tax-loss harvesting"
  ],
  "fees": [
    "Proposed annual advisory fee: 1% of AUM",
    "Financial planning services included",
    "Quarterly portfolio review meetings",
    "Tax planning consultation included"
  ],
  "progressOnPriorGoals": [
    "First time client - no prior goals to review"
  ],
  "newGoalsAndObjectives": [
    "Develop comprehensive retirement strategy",
    "Create college funding plan for children",
    "Optimize investment portfolio allocation",
    "Implement tax efficiency strategies"
  ],
  "financialPlanModifications": [
    "Initial plan to be developed based on discovery findings"
  ],
  "motivationsAndConcerns": [
    "Concern about market volatility",
    "Worried about college costs",
    "Interest in sustainable investing",
    "Desire for work-life balance in retirement"
  ],
  "advisorRecommendations": [
    "Increase retirement savings rate",
    "Diversify investment portfolio",
    "Implement debt reduction strategy",
    "Review insurance coverage",
    "Develop estate planning documents"
  ],
  "nextSteps": [
    "Schedule follow-up meeting in 2 weeks",
    "Gather additional account statements",
    "Complete risk assessment questionnaire",
    "Review proposed investment strategy"
  ]
}`;

export const MOCK_EMAIL_DISCOVERY = `Subject: Follow-up: Financial Planning Discovery Meeting & Next Steps

Dear Sarah,

Thank you for taking the time to meet with me today for your initial financial planning discovery session. I appreciate you sharing your financial goals and circumstances with us. I'm writing to confirm the key points discussed and outline our next steps.

Key points covered:
• Current combined household income of $270,000 annually
• Investment portfolio totaling $825,000 across various accounts
• Primary residence valued at $750,000 with $450,000 mortgage balance
• Retirement target age of 60 with desired annual income of $120,000
• College funding goals for your two children
• Interest in sustainable/ESG investing opportunities

Actions agreed upon:
• Develop comprehensive retirement planning strategy
• Create detailed college funding analysis for both children
• Review current investment allocation and tax efficiency
• Analyze debt reduction options, particularly for student loans
• Evaluate current insurance coverage and estate planning needs

Next steps:
I will:
• Prepare initial financial planning analysis based on information provided
• Research ESG investment options that align with your goals
• Draft preliminary asset allocation recommendations
• Prepare tax efficiency strategies for your review

You will:
• Complete the risk assessment questionnaire (link to be sent separately)
• Gather recent statements for all investment accounts
• Provide copies of current insurance policies
• Share any existing estate planning documents

We have scheduled our follow-up meeting for [DATE] at [TIME] to review these items in detail and discuss specific recommendations.

Please don't hesitate to reach out if you have any questions before our next meeting. I look forward to helping you achieve your financial goals.

Best regards,
[ADVISOR_NAME]

P.S. I've attached our standard financial planning agreement for your review.`;