import React, { Component } from 'react';
import Select from 'react-select';
import {
  BoltIcon,
} from "@heroicons/react/24/solid";
import { isEmpty } from '../shared';
import { TEMPLATES } from '../utils';
import ColourLoadingSpinner from './ColourLoadingSpinner';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

class TemplateDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: ""
    };
  }

  componentDidMount() {
    this.setState({selectedOption: this.props.selectedOption})
  }

  handleOptionChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleGenerateAnalysis = ({selectedOption}) => {
    const {handleTemplateSelection} = this.props;
    handleTemplateSelection({selectedOptionValue: selectedOption.value});
  }

  render() {
    const { selectedOption } = this.state;
    const { isLoadingAnalysis } = this.props;

    const options = [
      { value: 'annualAccountsLimited', label: 'Annual Accounts (Limited Company)' },
      { value: 'annualAccountsSoleTrader', label: 'Annual Accounts (Sole Trader/Partnership)' },
      { value: 'quarterlyBusinessReview', label: 'Quarterly Business Review' },
      { value: 'changeManagement', label: 'Change Management' },
      { value: 'prospect', label: 'Intro/Prospect' },
      { value: 'discovery', label: 'Discovery' },
      { value: 'review', label: 'Review' },
    ];

    return (
      <div>
         <div className="flex gap-x-4 items-center pt-8">
        <Select
          value={selectedOption}
          onChange={this.handleOptionChange}
          options={options}
          placeholder={"Generate Meeting Notes"}
          components={{
            IndicatorSeparator: () => null
          }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: "290px",
              fontSize: 13,
              fontFamily: "Plus Jakarta Sans",
              border: "none",
              boxShadow: "none",
              background: 'rgba(229,229,229,0.29)',
              '&:hover': {
                  background: 'rgba(229,229,229,0.29)',
                  cursor: "pointer"
              }
            }),
            placeholder: (baseStyles, state) => ({
              ...baseStyles,
              color: "black",
            }),
            input: (baseStyles, state) => ({
              ...baseStyles,
              margin: "0px",
              padding: "0px"
            }),
            valueContainer: (baseStyles, state) => ({
              ...baseStyles,
              padding: "0px 8px"
            }),
            container: (baseStyles, state) => ({
              ...baseStyles,
              border: "1.5px solid rgb(79 70 229)",
              borderRadius: "0.5rem",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }),
            menu: (baseStyles, state) => ({
                ...baseStyles,
                border: "2px solid #d5d3fd",
                boxShadow: "none",
                backgroundColor: "white",
                paddingTop: "8px",
                paddingBottom: "8px"
              }),
            option: (styles, { isSelected }) => ({
                ...styles,
              fontSize: 13,
              fontFamily: "Plus Jakarta Sans",
              backgroundColor: isSelected ? "#e0e0ffe0" : "white",
              color: "black",
              '&:hover': {
                    cursor: "pointer",
                    backgroundColor: "rgba(229,229,229,0.29)"
                }
              }),
          }}
        />
         
        </div>
          <div className = "pt-4 text-xs text-black/60 "><InformationCircleIcon className="inline mr-2 h-4 w-4" /> Note: Edited and saved transcripts will be used for analysis.</div>
          <div className="pt-4">
            {!isEmpty(selectedOption) && !isEmpty(selectedOption.value) && 
            <div>
                <div className="mb-4 font-jakarta text-indigo-800">Meeting notes will include:</div>
                <div className="gap-x-4">
                    {TEMPLATES[selectedOption.value].map((item, index) => (
                        <div key={index} className="mb-1 text-xs flex">
                            <span className="mr-2 text-black/60 text-sm flex-shrink-0 whitespace-pre"></span>
                            <span className="whitespace-pre">{item}</span>
                        </div>
                    ))}
                </div>
                {!isLoadingAnalysis && <span className="text-sm flex items-center justify-center pt-8 pb-4 text-indigo-500">You can only generate 1 notes template for this meeting</span>}
                <div className="py-4 flex w-full items-center justify-center">
                   {!isLoadingAnalysis && <button disabled={!selectedOption} className={`rounded-md bg-indigo-600 text-white font-bold px-4 py-2 text-sm flex justify-center items-center ${selectedOption ? "hover:opacity-60" : "opacity-35"}`} onClick={() => this.handleGenerateAnalysis({selectedOption})}>
                      <span>Generate Meeting Notes<BoltIcon className="inline ml-2 h-4 w-4" /></span>
                    </button>}
                   
                    {isLoadingAnalysis && <div className="flex flex-col items-center justify-center"><div className="animate-pulse text-indigo-600 text-opacity-55 pb-4">Generating Meeting Notes...</div><ColourLoadingSpinner /></div>}
                </div>
                
              </div>}
          </div>
      </div>
   
    );
  }
}

export default TemplateDropdown;