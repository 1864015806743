
import React, { Component } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ColourLoadingSpinner from "../ColourLoadingSpinner";

class DeleteSurveyModal extends Component {
  render() {
    const { handleDeleteModalCloseFn, handleDeleteModalConfirmation, isDeleteLoading = false } =
      this.props;
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-10/12 sm:w-1/2 rounded px-6 sm:px-12 pt-6 pb-12 shadow-lg border">
          <div className="flex justify-between items-center">
            <span />
            <XMarkIcon
              onClick={handleDeleteModalCloseFn}
              class="h-6 w-6 text-black/80 cursor-pointer hover:scale-110"
            />
          </div>
          <div class="flex justify-center items-center">
              <div class="py-8 font-lg ">
                Are you sure you want to delete this meeting?
                <p class="pt-8">
                  This will delete <b>ALL</b> data associated with this meeting.
                </p>
                <p class="pt-8">
                  This action can <b>NOT</b> be undone!
                </p>
              </div>
          </div>

          {isDeleteLoading && <div class="flex gap-8 w-full items-center justify-center mx-auto mt-8"><ColourLoadingSpinner /></div>}
        
          {!isDeleteLoading &&  <div class="flex gap-8 w-full items-center justify-center mx-auto mt-8">
            <button
              class="px-4 py-1 rounded-full border hover:opacity-50"
              onClick={handleDeleteModalCloseFn}
            >
              Cancel
            </button>
            <button
              class="px-4 py-1 rounded-full bg-red-700 text-white hover:opacity-50"
              onClick={handleDeleteModalConfirmation}
            >
              Delete
            </button>
          </div>}
        </div>
      </div>
    );
  }
}

export default DeleteSurveyModal;
