import React, { Component } from "react";
import { withRouter } from "./withRouterHook";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { updateUserPlanTypeToFree } from "../service/UsersService";
import { PLAN_TYPE, PRICING_PAGE } from "../shared";
import PricingCards from "./PricingCards";


class ConfirmPlan extends Component {
  state = { showContactModal: false };

  handleContactSales = () => {
    this.setState({ showContactModal: true });
  };

  handleModalClose = () => {
    this.setState({ showContactModal: false });
  };

  handleFreePlanButtonClick = async () => {
    //Update DB with user details to set isFreeTier: true
    const { user, history } = this.props;
    const { username } = user;
    await updateUserPlanTypeToFree({
      userId: username,
      planType: PLAN_TYPE.FREE,
    });
    history.push("/dashboard/meetings");
  };

  render() {
    const { showContactModal } = this.state;
    return (
      <PricingCards
          handleModalClose={this.handleModalClose}
          handleFreePlanButtonClick={this.handleFreePlanButtonClick}
          handleContactSales={this.handleContactSales}
          type={PRICING_PAGE.CONFIRM}
          showContactModal={showContactModal} 
      />
    );
  }
}

export default withAuthenticator(withRouter(ConfirmPlan));
