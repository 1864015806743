// RequireAuth.js
import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { awsExports } from "../aws-exports";

Amplify.configure(awsExports);

export function RequireAuth({ children }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated" && route !== "idle") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
