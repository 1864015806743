import React, { Component } from "react";
import { withRouter } from "./withRouterHook";
import LogoName from "../images/logo_name.png";
import LogoImage from "../images/logo_image.png";
import Hamburger from 'hamburger-react'
import { menuItemsArray } from "../shared";
import "../css/Hamburger.css";

class LandingPageHeader extends Component {

  state = {
    menuisOpen: false
  }

  handleMenuOpen = (menuisOpen) => {
    this.setState({menuisOpen: menuisOpen});
  }
  handleLoginClick = () => {
    const { history } = this.props;
    history.push("/login");
  };

  handlePricingClick = () => {
    const { history } = this.props;
    history.push("/pricing");
  };

  handleLogoClick = () => {
    const { history } = this.props;
    history.push("/");
  };

  handleTryFreeClick = () => {
    const { history } = this.props;
    history.push("/login");
  };

  showSettings (event) {
    event.preventDefault();
  
  }


  render() {
    const { showPricing = true, showLogin = true, showFreeTrial = false } = this.props;
    const {menuisOpen} = this.state
    return (
      <div className="sticky top-0 sm:px-6 lg:px-8 flex items-center justify-between h-24 relative z-30 backdrop-blur-sm bg-opacity-30 px-12 bg-neutral-200">
        <span>
          <div
            className="flex items-center justify-center flex-shrink-0 cursor-pointer"
            onClick={this.handleLogoClick}
          >
            <img src={LogoImage} className="h-6 w-6" alt="LogoImage" />
            <img src={LogoName} className="h-14 w-14" alt="LogoName" />
          </div>
        </span>
        <span />
        <div>
          {showPricing && (
            <span
              className="hidden sm:inline  px-1 sm:px-4 font-semibold cursor-pointer hover:opacity-70 font-manrope"
              onClick={this.handlePricingClick}
            >
              Pricing
            </span>
          )} 
          {showLogin && (
            <span className="hidden sm:inline  px-1 sm:px-4">
              <span
                className="font-semibold px-2 py-2 rounded-md cursor-pointer hover:opacity-70 font-manrope"
                onClick={this.handleLoginClick}
              >
                Log in
              </span>
            </span>
          )}
          {showFreeTrial && (
            <span className="hidden sm:inline px-1 sm:px-4">
              <button
                className="font-semibold px-2 py-2 rounded-md cursor-pointer hover:opacity-70 font-manrope text-white bg-indigo-500"
                onClick={this.handleTryFreeClick}
              >
                Free trial
              </button>
            </span>
          )}
          <div className="flex relative sm:hidden">
            <Hamburger toggled={menuisOpen} toggle={this.handleMenuOpen} size={24} direction="left" rounded className="z-50 absolute" />

              <div
                  className={`dropdown-menu ${menuisOpen ? 'block absolute -right-10 top-20 w-screen h-screen bg-white' : 'hidden'} ${menuisOpen ? 'animate-dropdown' : ''}`}  
                >
                  <ul className="flex flex-col items-center justify-center text-black text-lg gap-y-12 pt-10">
                    <li>
                      <span
                        className="px-1 sm:px-4  cursor-pointer hover:opacity-70 font-manrope"
                        onClick={this.handlePricingClick}
                      >
                        Pricing
                      </span>
                   </li>
                    <li> 
                      <span
                    className=" px-2 py-2 rounded-md cursor-pointer hover:opacity-70 font-manrope"
                    onClick={this.handleLoginClick}
                  >
                    Log in
                  </span>
                  </li> 
                    <li>  
                      <button
                          className="px-2 py-2 rounded-md cursor-pointer hover:opacity-70 font-manrope text-white bg-indigo-500"
                          onClick={this.handleTryFreeClick}
                        >
                          Free trial
                        </button>
                    </li>
                  </ul>
                </div> 

          </div>
      
        </div>
      </div>
    );
  }
}

export default withRouter(LandingPageHeader);
