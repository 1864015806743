import React from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { 
  CheckIcon, 
  ArrowTrendingDownIcon, 
  UserPlusIcon, 
  ShieldCheckIcon, 
  ArrowUpTrayIcon 
} from "@heroicons/react/24/outline";
import 'react-lazy-load-image-component/src/effects/blur.css';

// Import components
import LandingPageHeader from "../LandingPageHeader";

// Import images
import LogoName from "../../images/logo_name.png";
import LogoImage from "../../images/logo_image.png";
import IntroCall from "./images/introcall.png";
import coworkers from "./images/coworkers.webp";
import edits from "../../images/edits.png";
import email from "./images/email.png";
import Google from "./images/googlemeet.jpg";
import Teams from "./images/teams.jpg";
import Zoom from "./images/zoom.png";
import soc2 from "./images/soc2.png";
import gdpr from "./images/gdpr.png";
import ccpa from "./images/ccpa.jpg";

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="w-80 sm:w-96 border border-indigo-900 border-opacity-70 p-6 sm:p-8 rounded-lg flex flex-col justify-between">
    <div className="flex pb-8 items-center">
      <Icon className="flex-shrink-0 mr-4 h-8 w-8 text-indigo-600" />
      <div className="font-manrope text-xl sm:text-2xl font-bold">{title}</div>
    </div>
    <div className="text-sm sm:text-base">{description}</div>
  </div>
);

const ComplianceLogos = () => (
  <div className="flex gap-x-4">
    <div className="text-sm text-center">
      <img src={soc2} className="h-12 w-12 mb-2" alt="SOC2" />SOC2
    </div>
    <div className="text-sm text-center">
      <img src={gdpr} className="h-12 w-12 mb-2" alt="GDPR" />GDPR
    </div>
    <div className="text-sm text-center">
      <img src={ccpa} className="h-12 w-12 mb-2" alt="CCPA" />CCPA
    </div>
  </div>
);

const IntegrationSection = () => (
  <div className="flex items-center justify-start">
    <div className="flex flex-col sm:flex-row justify-center items-center gap-y-4 sm:gap-x-12 pt-4 sm:pt-8 sm:pb-8 lg:pt-0">
      <div className="text-center">
        <div className="flex">
          <span><img className="inline h-10 w-10 bg-transparent mx-1 mr-2" src={Google} alt="Google Meet" /></span>
          <span><img className="inline h-10 w-10 sm:mx-2 mx-1 bg-white" src={Teams} alt="Teams" /></span>
          <span><img className="inline h-10 w-10 sm:mx-2 mx-1 bg-white" src={Zoom} alt="Zoom" /></span>
        </div>
        <div className="text-sm text-center pt-4">Works with</div>
      </div>
      <ComplianceLogos />
    </div>
  </div>
);

const HeroSection = ({ onTryFree, onDemo }) => (
  <div className="section1 px-2 sm:px-6 md:px-0 xl:px-4 pt-20 pb-6 sm:pt-32 text-deep-indigo lg:flex sm:max-w-[1480px] sm:mx-auto justify-center">
    <div className="flex flex-col gap-y-6 lg:gap-y-12 lg:w-1/2 sm:px-20">
      <div className="flex flex-col mb-4 text-center lg:text-left px-0.5 lg:px-0 text-black text-5xl lg:text-6xl font-manrope">
        AI meeting assistant for
        <span className="text-indigo-500">financial advisors</span>
      </div>
      <p className="md:text-lg text-center lg:text-left px-8 sm:px-0 leading-6 text-black/80">
        Transform your client meetings with AI-powered note-taking. While you build meaningful relationships, Xylo captures every detail with 99% accuracy and turns conversations into actionable insights.
      </p>
      <div className="flex flex-col sm:flex-row sm:space-x-8 justify-center items-center lg:justify-start sm:items-start">
        <div className="flex flex-col">
          <button
            className="bg-indigo-500 hover:opacity-70 text-white px-6 py-3 rounded-md font-semibold sm:mb-0"
            onClick={onTryFree}
          >
            Get started - it's free
          </button>
          <div className="text-black/60 text-sm pt-2 mb-8 sm:mb-0 text-center sm:text-left">
            No credit card required
          </div>
        </div>
        <button
          className="bg-white hover:opacity-70 text-black border border-black px-6 py-3 rounded-md font-semibold"
          onClick={onDemo}
        >
          Book a demo
        </button>
      </div>
    </div>
    <HeroImage />
  </div>
);

const HeroImage = () => (
  <>
    <div className="hidden lg:flex lg:flex-col pt-12 sm:pt-0 sm:w-1/2 px-2 items-center justify-center">
      <ImageWithIntegrations />
    </div>
    <div className="lg:hidden flex flex-col pt-6 lg:w-1/2 self-center rounded-lg px-4 items-center justify-center">
      <ImageWithIntegrations />
    </div>
  </>
);

const ImageWithIntegrations = () => (
  <>
    <div className="rounded-xl flex items-center justify-center max-w-lg">
      <div className="bg-white rounded-xl overflow-hidden px-12 pb-6">
        <LazyLoadImage
          src={coworkers}
          alt="Coworkers in meeting"
          effect="blur"
          placeholderSrc={coworkers}
          priority={true}
          width="100%"
          height="auto"
        />
      </div>
    </div>
    <IntegrationSection />
  </>
);

const KeyDetailsSection = () => (
  <section className="section4 flex flex-col sm:flex-row sm:max-w-[1380px] sm:mx-auto py-4 sm:py-20 px-18 items-center justify-center">
    <div className="sm:w-1/2 px-8 sm:px-16 self-center pb-8 sm:pb-0 text-base sm:text-lg order-1">
      <div>
        <div className="text-3xl sm:text-4xl font-black pb-8 sm:pb-12 font-manrope self-center">
          Turn conversations into <span className="text-indigo-500">structured insights</span> automatically
        </div>
        <div className="pb-4 flex flex-row text-base items-center">
         <CheckIcon className="flex-shrink-0 inline mr-4 text-black/70 h-5 w-5" />
          Let Xylo automatically take notes during your virtual meetings.
        </div>
        <div className="pb-4 flex flex-row text-base items-center">
         <CheckIcon className="flex-shrink-0 inline mr-4 text-black/70 h-5 w-5" />
          Get instant analysis of financial goals, risk tolerance, and key client details.
        </div>
        <div className="pb-4 flex flex-row text-base items-center">
          <CheckIcon className="flex-shrink-0 inline mr-4 text-black/70 h-5 w-5" />
          Choose specialized templates for every meeting type - from prospect calls to annual reviews and tax planning.
        </div>
      </div>
    </div>
    <div className="sm:w-3/5 px-2 order-2 pb-8 sm:pb-0">
      <div className="p-2 sm:p-8 bg-gradient-to-br from-indigo-50 to-indigo-100/50 rounded-xl flex items-center justify-center max-w-xl">
        <div>
          <LazyLoadImage src={IntroCall} alt="Intro call interface" />
        </div>
      </div>
    </div>
  </section>
);

const AccuracySection = () => (
  <section className="section4 flex flex-col sm:flex-row sm:max-w-[1380px] sm:mx-auto py-4 sm:py-20 px-18 items-center justify-center">
    <div className="sm:w-1/2 px-8 sm:px-20 self-center pb-8 sm:pb-0 text-base sm:text-lg order-1 sm:order-2">
      <div>
        <div className="text-3xl sm:text-4xl font-black pb-8 sm:pb-12 font-manrope self-center">
          <span className="text-indigo-500">Never miss</span> critical client details
        </div>
        <div className="text-base">
          Capture every detail of your client conversations with unmatched precision - from investment preferences and risk tolerance to financial milestones and retirement goals. Xylo's AI technology ensures 99% accuracy while automatically organizing your notes into clear, actionable categories.
        </div>
      </div>
    </div>
    <div className="sm:w-3/5 px-2 sm:px-8 mx-auto order-2 sm:order-1 pb-8 sm:pb-0">
      <div className="p-2 sm:p-8 bg-gradient-to-br from-indigo-50 to-indigo-100/50 rounded-xl flex items-center justify-center max-w-xl">
        <div className="bg-white rounded-xl overflow-hidden">
          <LazyLoadImage src={edits} alt="Transcript editing interface" />
        </div>
      </div>
    </div>
  </section>
);

const FollowUpSection = () => (
  <section className="section4 flex flex-col sm:flex-row sm:max-w-[1380px] sm:mx-auto py-4 sm:py-20 px-18 items-center justify-center">
    <div className="sm:w-1/2 px-8 sm:px-20 self-center pb-8 sm:pb-0 text-base sm:text-lg order-1">
      <div>
        <div className="text-3xl sm:text-4xl font-black pb-8 sm:pb-12 font-manrope self-center">
          Follow up with clients in <span className="text-indigo-500">seconds</span>
        </div>
        <div className="text-base">
          After each meeting, Xylo automatically generates a prioritized action list and crafts personalized email drafts that capture key discussion points and next steps. Save hours of administrative work while ensuring consistent, professional communication with every client.
        </div>
      </div>
    </div>
    <div className="sm:w-3/5 px-2 sm:px-8 mx-auto order-2 pb-8 sm:pb-0">
      <div className="p-2 sm:p-8 bg-gradient-to-br from-indigo-50 to-indigo-100/50 rounded-xl flex items-center justify-center max-w-lg">
        <div className="bg-white rounded-xl overflow-hidden p-4">
          <LazyLoadImage src={email} alt="Email follow-up interface" />
        </div>
      </div>
    </div>
  </section>
);

const CallToActionSection = () => (
  <section className="sm:max-w-[1380px] sm:mx-auto justify-between py-20 px-18">
    <div className="rounded-lg flex items-center justify-center">
      <div className="flex flex-col gap-y-4 font-manrope justify-center px-8 sm:px-0">
        <div className="text-3xl sm:text-4xl text-center">
          Spend more time <span className="text-indigo-500">servicing clients</span>, and less time creating notes
        </div>
        <div className="flex flex-col sm:flex-row gap-x-4 gap-y-8 items-center justify-center pt-8">
          <button
            className="bg-indigo-500 hover:opacity-70 text-white px-6 py-3 rounded-md font-semibold"
            onClick={() => handleNavigation('/login')}
          >
            Get started - it's free
          </button>
          <button
            className="bg-white hover:opacity-70 text-black border border-black px-6 py-3 rounded-md font-semibold"
            onClick={() => handleNavigation('/contact')}
          >
            Book a demo
          </button>
        </div>
      </div>
    </div>
  </section>
);

const LandingPageFinance = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <LandingPageHeader showFreeTrial={true} />
      
      <HeroSection 
        onTryFree={() => handleNavigation('/login')}
        onDemo={() => handleNavigation('/contact')}
      />

      <KeyDetailsSection />
      <AccuracySection />
      <FollowUpSection />
      
      <section className="sm:max-w-[1380px] sm:mx-auto py-4 sm:py-20 px-18 bg-white">
        <div className="flex flex-col items-center justify-center gap-y-12 sm:gap-y-16">
          <div className="flex flex-col gap-y-12 sm:gap-y-0 sm:flex-row gap-x-16">
            <FeatureCard 
              icon={ArrowTrendingDownIcon}
              title="Save 10+ hours every week"
              description="Eliminate manual note-taking and follow-up tasks. Focus on what matters most - building client relationships and growing your practice."
            />
            <FeatureCard 
              icon={UserPlusIcon}
              title="Deliver exceptional service"
              description="Spend more time meeting prospects and focusing on servicing existing clients better, resulting in more clients and happier clients."
            />
          </div>
          <div className="flex flex-col gap-y-12 sm:gap-y-0 sm:flex-row gap-x-16">
            <FeatureCard 
              icon={ShieldCheckIcon}
              title="Stay compliant effortlessly"
              description="Create detailed meeting records automatically. Maintain a clear audit trail and meet regulatory requirements without extra work."
            />
            <FeatureCard 
              icon={ArrowUpTrayIcon}
              title="Seamless workflow integration"
              description="Edit and customize AI-generated notes, then sync them directly to your CRM. No double data entry needed."
            />
          </div>
        </div>
      </section>

      <CallToActionSection />

      <footer className="bg-neutral-200 bg-opacity-40">
        <div className="px-6 py-12 sm:px-12 sm:py-16">
          <div className="flex sm:flex-row items-center justify-between w-full">
            <div className="flex items-center justify-center flex-shrink-0">
              <img src={LogoImage} className="h-6 w-6" alt="Logo Image" />
              <img src={LogoName} className="h-14 w-14" alt="Logo Name" />
            </div>
            <div className="text-sm flex flex-col sm:flex-row gap-y-4 sm:gap-y-0 sm:gap-x-6">
              <span
                className="cursor-pointer px-2 sm:px-6 hover:opacity-60"
                onClick={() => handleNavigation('/contact')}
              >
                Contact Us
              </span>
              <span
                className="cursor-pointer px-2 sm:px-6 hover:opacity-60"
                onClick={() => handleNavigation('/terms')}
              >
                Terms And Conditions
              </span>
              <span
                className="cursor-pointer px-2 sm:px-6 hover:opacity-60"
                onClick={() => handleNavigation('/privacy')}
              >
                Privacy Policy
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPageFinance;
