import React, { Component } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

class ErrorModal extends Component {
  render() {
    const { text, handleModalCloseFn, hideCloseButton = false } = this.props;
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-dusky-orange w-11/12 sm:w-1/2 rounded p-6 shadow-lg">
          <div className="flex justify-between items-center">
            <span />
            <h2 className="text-lg font-bold">Error</h2>
            {!hideCloseButton ? (
              <XMarkIcon
                onClick={handleModalCloseFn}
                class="h-6 w-6 text-black/80 cursor-pointer hover:scale-110"
              />
            ) : (
              <span />
            )}
          </div>

          <p class="tracking-tight pt-6">
            {text ||
              "Sorry! There was a problem creating your survey. Please contact support at amrit@helloxylo.com"}
          </p>
        </div>
      </div>
    );
  }
}

export default ErrorModal;
