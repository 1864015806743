import React, { Component } from "react";
import Header from "./Header";
import { withRouter } from "./withRouterHook";
import { PlusIcon, ChevronDownIcon, ChevronRightIcon, ArrowPathIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { ERROR_MESSAGES, MENU_ITEMS, isEmpty } from "../shared";
import LargeLoadingSpinner from "./LargeLoadingSpinner";
import { getUserDetails } from "../service/UsersService";
import UpgradeModal from "./Modals/UpgradeModal";
import ExpiredModal from "./Modals/ExpiredModal";
import ErrorModal from "./Modals/ErrorModal";
import MeetingDiv from "./MeetingDiv";
import DatePicker, {getDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { createMeeting, getAllMeetingsForUser } from "../service/CreateMeeting";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ColourLoadingSpinner from "./ColourLoadingSpinner";
import CreatableInput from "./CreatableInput";
import { getMomentTz, getMomentTzMinusScheduleTime, getTimezoneInLetters } from "../utils";

class DashboardMeetings extends Component {
  state = {
    meetings: [],
    isLoading: false,
    showUpgradeModal: false,
    showExpiredModal: false,
    userAtSurveyLimit: false,
    showErrorModal: false,
    errorMessage: "",
    showDashboard: false, //Set to false in case we need to show the /confirm-plan page if this is a new user sign up
    selectedMenuItem: MENU_ITEMS.MEETINGS,
    showMeetingModal: false,
    meetingURL: "",
    datePickerValue: null,
    redactPii: false,
    showDeleteModal: false,
    meetingName: "",
    createMeetingIsLoading: false,
    wordBoostArray: [],
    showUpgradeModal: false,
    showScheduled: true,
    showPastMeetings: true
  };

  async componentDidMount() {
    const { user, history } = this.props;
    const now = new Date();
    const todaysDate = this.convertToUserTimeZone(now); 
    this.setState({datePickerValue: todaysDate});
    if (user) {
      const { username } = user;
      this.setState({ isLoading: true });
  
      // Check if the value is already stored in session storage
      let hasSelectedPlan = sessionStorage.getItem('hasSelectedPlan');
  
      if (hasSelectedPlan === null) {
        // If not stored, fetch the value
        const userDetails = await getUserDetails({ userId: username });
        if (isEmpty(userDetails)) {
          this.setState({ showErrorModal: true, errorMessage: ERROR_MESSAGES.DASHBOARD_USER_DETAILS });
          return;
        }
        const { user: userInformation } = userDetails;
        if (isEmpty(userInformation)) {
          this.setState({ showErrorModal: true, errorMessage: ERROR_MESSAGES.DASHBOARD_USER_INFORMATION });
          return;
        }
        // Get hasSelectedPlan from userDetails
        hasSelectedPlan = userInformation.hasSelectedPlan;
        
        // Store hasSelectedPlan in session storage
        sessionStorage.setItem('hasSelectedPlan', hasSelectedPlan);
      }
  
      // Proceed with the stored value
      if (hasSelectedPlan === false) {
        history.push("/confirm-plan");
        return;
      } else {
        const meetings = await getAllMeetingsForUser({ userId: username });
        this.setState({
          meetings,
          isLoading: false,
          showDashboard: true,
          userAtSurveyLimit: false
        });
      }
    }
  }

  handleMeetingURLChange = (e) => {
    this.setState({ meetingURL: e.target.value });
  };

  handleMeetingNameChange = (e) => {
    this.setState({ meetingName: e.target.value });
  };
  
  handleErrorModalCloseFn = () => {
    this.props.history.push(`/`);
  }

  handleNewMeetingClick = async () => {
    try {
      const { user } = this.props;
      const { username } = user;
      const userDetails = await getUserDetails({ userId: username });
      const { user: userInformation } = userDetails;
      const {hasFreeTierExpired, isStarterTier, isProTier} = userInformation;
      const showUpgradeModal = (hasFreeTierExpired && !isStarterTier && !isProTier);
      if(showUpgradeModal){
        this.setState({showUpgradeModal: true})
      }
      else {
        this.setState({showMeetingModal: true})
      }
    } catch(err) {
      this.setState({showMeetingModal: true})
    }
  };

  handleModalCloseFn = () => {
    this.setState({ showUpgradeModal: false });
  };

  handleMenuItemClick = async (item) => {
    const {history} = this.props;
    if(item ===  MENU_ITEMS.RECORDINGS) {
      history.push("/dashboard/recordings")
    }
    else  if(item ===  MENU_ITEMS.UPLOADS) {
      history.push("/dashboard/uploads")
    }
    else  if(item ===  MENU_ITEMS.MEETINGS) {
      history.push("/dashboard/meetings")
    }
  }

  handleNewMeetingClose = () => {
    this.setState({showMeetingModal: false})
  }

  handleMeetingClick = async ({meetingId, status}) => {
    if(status === "Processed"){
      this.props.history.push(`meeting/${meetingId}`);
    }
    else {
      return;
    }
  }

  handleCreateMeeting =  async () => {
    this.setState({createMeetingIsLoading: true})
    const { user } = this.props;
    const { username } = user;
    const {meetingURL, datePickerValue, redactPii, meetingName, wordBoostArray} = this.state;
    const joinAt = getMomentTzMinusScheduleTime(datePickerValue);
    const meetingTime = getMomentTz(datePickerValue);
    //Call API to create meeting bot with join_at time set to 20 minutes before the datePicker meeting time
    await createMeeting({meetingURL, joinAt, meetingTime, userId: username, redactPii, meetingName, wordBoostArray});
    this.setState({showMeetingModal: false, createMeetingIsLoading: false});
    window.location.reload();
  }

  handleRedactPiiClick = (checked) => {
    this.setState({redactPii: checked});
  }

  handleDeleteBlur=({showDeleteModal}) => {
    this.setState({showDeleteModal})
  }

  handleVocabChange = (newValue) => {
    const wordBoostArray = !isEmpty(newValue) && Array.isArray(newValue) ? newValue.map((item => item.value)): [];
    this.setState({wordBoostArray});
  }

  convertToUserTimeZone = (date) => {
    if(!date) return null;
    const userTimeZone = this.getUserTimeZone();
    return new Date(date.toLocaleString("en-US", { timeZone: userTimeZone }));
  };

  getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  convertToUTC = (date) => {
    if(!date) return null;
    return new Date(date.toISOString());
  };
  
  handleDatePickerChange = (date) => {
    const utcDate = this.convertToUTC(date);
    this.setState({datePickerValue: utcDate});
  };

  handlePageRefresh = () => {
    window.location.reload();
  }

  render() {
    const {
      isLoading,
      showUpgradeModal,
      showExpiredModal,
      showDashboard,
      showErrorModal,
      errorMessage,
      selectedMenuItem,
      meetings,
      showMeetingModal,
      meetingURL,
      datePickerValue,
      redactPii,
      showDeleteModal,
      meetingName,
      createMeetingIsLoading
    } = this.state;
    const blur = showUpgradeModal || showExpiredModal || showUpgradeModal;
    const selected = datePickerValue ? this.convertToUserTimeZone(datePickerValue) : null;
    const timezoneLetters = getTimezoneInLetters(datePickerValue);

    const sortMeetingsByTime = (meetings) => {
      try {
        return meetings.sort((a, b) => {
          // Check if both meetings have valid meetingTime
          if (!a.meetingTime || !b.meetingTime) return 0;
          
          // Attempt to create Date objects
          const dateA = new Date(a.meetingTime);
          const dateB = new Date(b.meetingTime);
          
          // Verify both dates are valid
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) return 0;
          
          return dateB - dateA;
        });
      } catch (error) {
        console.warn('Error sorting meetings:', error);
        return meetings; // Return unsorted meetings if sort fails
      }
    };

    const scheduledMeetings = !isEmpty(meetings) ? sortMeetingsByTime(
      meetings.filter(meeting => meeting.status !== "Processed")
    ) : null;

    const processedMeetings = !isEmpty(meetings) ? sortMeetingsByTime(
      meetings.filter(meeting => meeting.status === "Processed")
    ) : null;

    return (
      <div className={`flex flex-col h-screen`}>
        {showErrorModal && <ErrorModal
            text={errorMessage}
            handleModalCloseFn={this.handleErrorModalCloseFn}
          />
        }
        {showDashboard && !showErrorModal && (
          <div className="flex flex-col h-screen">
            <Header />
            <div className="flex-grow pt-8 sm:pt-8 px-4 xl:px-24 mx-auto w-full max-w-screen-3xl sm:pb-36">
              {showExpiredModal && <ExpiredModal />}
              {showUpgradeModal && (
                <UpgradeModal handleModalCloseFn={this.handleModalCloseFn} />
              )}
          <div className="flex flex-row h-full">
              {/* RHS VIEW - UPLOADS */}
                {<div className={`${isEmpty(meetings) ? "w-full" : "sm:w-7/8"} sm:px-4 xl:px-12 mx-auto`}>
                  <div
                    className={`mb-6 sm:mb-12 flex flex-row flex-wrap justify-between ${
                      blur ? "blur-lg" : ""
                    }`}
                  >
                    <span className={`text-xl uppercase tracking-wide text-black font-semibold font-manrope ${showMeetingModal ? "blur-lg" : ""}`}>
                      {!isEmpty(meetings) ? `${meetings.length} ` : ''}Meetings
                    </span>
                    <span>
                      <button
                        className={`rounded-full px-2 py-1 sm:px-4 sm:py-2 bg-black border text-white uppercase flex flex-start text-sm sm:text-base items-center cursor-pointer hover:scale-105 hover:shadow-xl ${showMeetingModal ? "blur-lg" : ""}`}
                        onClick={this.handleNewMeetingClick}
                      >
                        <PlusIcon className="h-4 w-4 sm:h-5 sm:w-5 inline mr-2 stroke-2" />
                        <span className="font-bold font-manrope">New Meeting</span>
                      </button>
                    </span>
                  </div>
                  {/* List of Uploads */}
                  {isLoading && (
                    <div className="flex justify-center items-center pt-10 h-screen">
                      <LargeLoadingSpinner />
                    </div>
                  )}
                  {!isLoading && !showMeetingModal && 
                  <div className="mb-8 flex items-center" onClick={this.handlePageRefresh}>
                    <div className="flex items-center justify-center rounded-md bg-lime-600 p-1 cursor-pointer hover:opacity-65 mr-4">
                     <ArrowPathIcon className="text-white inline h-5 w-5 stroke-2" />
                    </div>
                  
                    <span className="text-xs text-black/80">Refresh to see latest meeting status</span>
                  </div>}
                  {!isLoading && (
                    <div
                      className={`w-full flex flex-row flex-start items-stretch ${
                        blur ? "blur-lg" : ""
                      }`}
                    >
                      <div className="w-full">
                     {showUpgradeModal && <UpgradeModal />}
                      {isEmpty(meetings) && !showMeetingModal && <div>
                        <div className="mx-4 font-manrope flex flex-col items-center justify-center py-8">
                           <div className="pb-6 font-semibold text-lg">Set up your first meeting</div>
                           <div className="text-black/60 pb-4">Create your meeting, and come back here to see the analysis!</div>
                           <div className="text-black/60">Check out our <a href="https://www.helloxylo.com/onboarding" target="_blank" className="underline text-indigo-600">guide</a> to get started</div>
                        </div>
                      </div>}
                      {showMeetingModal && 
                      <div className="border border-indigo-600 w-fullrounded-lg shadow-lg px-8 py-6 lg:mx-auto text-sm">
                      <div className='flex justify-between pb-10'>
                        <span/>
                        <span className=''>Enter New Meeting Details</span>
                        <span><XMarkIcon className={"inline h-6 w-6 cursor-pointer hover:scale-110 hover:shadow-xl"} onClick={this.handleNewMeetingClose} /></span>
                      </div>
                      <div className="grid grid-cols-3 gap-y-4">
                        <div className="flex flex-shrink-0 items-center">Meeting Name</div>
                        <span className="col-span-2"> <input
                                className="text-sm flex items-center w-full leading-3 p-2 text-md focus:outline-none focus:ring-1 focus:ring-white font-manrope border-b-2 border-neutral-200"
                                value={meetingName}
                                onChange={this.handleMeetingNameChange}
                                placeholder={"Intro Call"}
                               ></input>
                          </span>
                          <div className="flex flex-shrink-0">
                              <div className="flex items-center justify-center flex-shrink-0 ">Meeting Link <span className="text-red-600">*</span></div>
                              <Tooltip 
                                  placement="right"
                                  TransitionComponent={Zoom} 
                                  slotProps={{
                                    tooltip: {
                                      sx: {
                                        color: "#514E6A",
                                        backgroundColor: "#f0f0f0",
                                        padding: "10px",
                                        fontSize: "12px"
                                      },
                                    },
                                  }}
                                title={<a href="https://www.helloxylo.com/get-meeting-link" target="_blank" className="underline text-blue-600">How to get a meeting link</a>}>
                                <Button size="small" style={{maxWidth: '30px', minWidth: '30px'}}><InformationCircleIcon className="h-6 w-6" /></Button>
                              </Tooltip>
                          </div>
                          <span className="col-span-2"><input
                            className="text-sm flex items-center w-full leading-3 p-2 text-md focus:outline-none focus:ring-1 focus:ring-white font-manrope border-b-2 border-neutral-200"
                            value={meetingURL}
                            onChange={this.handleMeetingURLChange}
                            placeholder={"https://meet.google.com/nnt-puqz-xoa..."}
                          ></input>
                          </span>
                          {/* Meeting Date & Time */}
                          <div className='flex items-center'><span className="flex flex-shrink-0">Meeting Date & Time <span className="text-red-600">*</span> </span></div>
                          <div className="font-manrope p-2 border-b-2">
                              <DatePicker 
                                  selected={selected} 
                                  onChange={this.handleDatePickerChange}
                                  showTimeSelect
                                  dateFormat="MMM d,yyyy h:mm aa"
                                  timeIntervals={15}
                                  style={{ width: '450px' }} 
                              />
                            </div>
                            <span className="ml-2 text-xs text-black/80 flex items-center justify-center">{!isEmpty(timezoneLetters) ? timezoneLetters : ""}</span>
                           <div className="text-black/90 flex items-center flex-shrink-0">
                            Custom Vocabulary
                            <Tooltip TransitionComponent={Zoom} title="To improve the transcription accuracy, you can boost certain words or phrases that will appear frequently in your meeting.">
                                <Button size="small" style={{maxWidth: '30px', minWidth: '30px'}}><InformationCircleIcon className="h-6 w-6" /></Button>
                            </Tooltip>
                            </div>
                            <div className="col-span-2 font-manrope text-sm">
                            <CreatableInput onVocabChange={this.handleVocabChange} />
                            </div>
                            {/* Redact PII */}
                            <div className="flex items-center">
                                <input
                                name="pii-checkbox"
                                type="checkbox"
                                className="accent-black cursor-pointer w-5 h-5 rounded-xl p-1 "
                                checked={redactPii}
                                onChange={(e) =>
                                  this.handleRedactPiiClick(e.target.checked)
                                }
                              />
                              <div className="ml-2 text-black/90">
                                Redact PII 
                                <Tooltip TransitionComponent={Zoom} title="Redact the following information from meetings and transcripts: US Social Security Number, Credit Card Number, Credit Card Expiration, Credit Card CVV, Phone Number, Date of Birth, Person Name, Organization, Drivers License, Banking Information">
                                  <Button size="small" style={{maxWidth: '30px', minWidth: '30px'}}><InformationCircleIcon className="h-6 w-6" /></Button>
                                </Tooltip>
                              </div>
                            </div>
                        </div>
                          {/* Create Meeting */}
                          {createMeetingIsLoading && <div className="flex justify-center py-8"><ColourLoadingSpinner /></div>}
                         {!createMeetingIsLoading && <div className="flex justify-center py-8"> 
                           <button
                            disabled={isEmpty(meetingURL)}
                            className={`rounded-full px-2 py-1 sm:px-4 sm:py-2 bg-indigo-500 border text-white uppercase flex flex-start text-sm sm:text-base items-center  ${isEmpty(meetingURL) ? "opacity-20 cursor-not-allowed" : "cursor-pointer hover:scale-105 hover:shadow-xl"}`}
                            onClick={this.handleCreateMeeting}
                          >
                            <div>
                              <span className="font-bold font-manrope">Create Meeting</span>
                              <ChevronRightIcon className="h-4 w-4 sm:h-5 sm:w-5 inline ml-2 stroke-2" />
                            </div>
                           </button>
                          </div>
                          }
                      </div>                
                      }
                        {!isEmpty(meetings) && (
                          <div className={`${showMeetingModal ? "blur-lg" : ""}`}>
                            {/* Scheduled Meetings Section */}
                            {!isEmpty(scheduledMeetings) && (
                              <div className="mb-6 w-full sm:w-[500px] md:w-[800px] lg:w-[900px] xl:w-[900px] 2xl:w-[900px] 3xl:w-[1100px]">
                                <div className="flex items-center gap-2 mb-4 cursor-pointer"
                                     onClick={() => this.setState({ showScheduled: !this.state.showScheduled })}>
                                  <ChevronDownIcon className={`text-indigo-600 h-5 w-5 transform transition-transform ${this.state.showScheduled ? 'rotate-180' : ''}`} />
                                  <h2 className="font-semibold text-indigo-600">Scheduled ({scheduledMeetings.length})</h2>
                                </div>
                                
                                {this.state.showScheduled && scheduledMeetings.map((meeting) => (
                                  <div key={meeting._id} data-meeting-id={meeting._id}>
                                    <MeetingDiv 
                                      meeting={meeting} 
                                      handleMeetingClickFn={this.handleMeetingClick} 
                                      handleDeleteBlur={this.handleDeleteBlur} 
                                      blurDiv={showDeleteModal}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}

                            {/* Past Meetings */}
                            {!isEmpty(processedMeetings) && (
                              <div className="mb-6 w-full sm:w-[500px] md:w-[800px] lg:w-[900px] xl:w-[900px] 2xl:w-[900px] 3xl:w-[1100px]">
                                <div className="flex items-center gap-2 mb-4 cursor-pointer"
                                     onClick={() => this.setState({ showPastMeetings: !this.state.showPastMeetings })}>
                                  <ChevronDownIcon className={`text-indigo-600 h-5 w-5 transform transition-transform ${this.state.showPastMeetings ? 'rotate-180' : ''}`} />
                                  <h2 className="font-semibold text-indigo-600">Past Meetings ({processedMeetings.length})</h2>
                                </div>
                                
                                {this.state.showPastMeetings && processedMeetings.map((meeting) => (
                                  <div key={meeting._id} data-meeting-id={meeting._id}>
                                    <MeetingDiv 
                                      meeting={meeting} 
                                      handleMeetingClickFn={this.handleMeetingClick} 
                                      handleDeleteBlur={this.handleDeleteBlur} 
                                      blurDiv={showDeleteModal}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withAuthenticator(withRouter(DashboardMeetings));
