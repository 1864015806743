import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { withRouter } from "./components/withRouterHook";
import { Amplify } from "aws-amplify";
import { awsExports } from "./aws-exports";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LogRocket from 'logrocket';

import LoginPage from "./components/LoginPage";
import { RequireAuth } from "./components/RequireAuth";
import PricingPage from "./components/PricingPage";
import ConfirmPlan from "./components/ConfirmPricingPlan";
import UpgradePlan from "./components/UpgradePlan";
import ContactPage from "./components/ContactPage";
import MeetingDetails from "./components/MeetingDetails.js";
import DashboardMeetings from "./components/DashboardMeetings.js";
import Onboarding from "./components/Onboarding.js";
import LandingPageFinance from "./components/LandingPage/LandingPageFinance.js";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Teams from "./components/Teams";
import Updates from "./components/Updates.js";

LogRocket.init('6k8dyq/xylo');
//AUTHENTICATION
Amplify.configure(awsExports);

class App extends Component {
  state = {
    surveyName: "",
  };

  setSurveyName = (surveyName) => {
    this.setState({ surveyName: surveyName });
  };

  updateSurveyName = ({ surveyName }) => {
    this.setSurveyName(surveyName);
  };

  render() {
    const { surveyName } = this.state;
    return (
      <section className="w-screen font-jakarta">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes>
          <Route exact path="/" element={<LandingPageFinance />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/onboarding" element={<Onboarding />} />
          <Route exact path="/updates" element={<Updates />} />
          <Route
            key="login-page"
            exact
            path="/login"
            element={<LoginPage props={"login-page"} />}
          />

          <Route exact path="/pricing" element={<PricingPage />} />
          <Route exact path="/upgrade-plan" element={<UpgradePlan />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/get-meeting-link" element={<Teams />} />
          <Route
            path="/confirm-plan"
            element={
              <RequireAuth>
                <ConfirmPlan />
              </RequireAuth>
            }
          />
         
            <Route
            path="/dashboard/meetings"
            element={
              <RequireAuth>
                <DashboardMeetings />
              </RequireAuth>
            }
          />
            
          <Route
            path="dashboard/meetings/meeting/:meetingId"
            element={
              <RequireAuth>
                <MeetingDetails />
              </RequireAuth>
            }
          />

        </Routes>
        </LocalizationProvider>
      </section>
    );
  }
}

export default withRouter(App);
