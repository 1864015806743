import { Amplify } from "aws-amplify";
import { awsExports } from "../aws-exports.js";
import axios from "axios";
import { isEmpty } from "../shared";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

//API to get details about a user given their userID (this is the cognitoId in the database)
export const getUserDetails = async ({ userId }) => {
  try {
    const response = await axios.get(
      `${awsExports.USERS_URL}?userId=${userId}`
    );
    if (isEmpty(response) || isEmpty(response.data)) return null;
    return response.data;
  } catch (err) {
    console.log(
      "ERROR getting user details from",
      `${awsExports.USERS_URL}?userId=${userId}`,
      err
    );
    return null;
  }
};

//When user selects FREE plan only, call BE to update details in BE
export const updateUserPlanTypeToFree = async ({ userId, planType }) => {
  try {
    const currentDate = new Date();
    const currentTimestamp = currentDate.toISOString();
    const payload = { userId, planType, createdTimestampFree: currentTimestamp };
    await axios.post(`${awsExports.USERS_URL}`, payload);
  }
  catch (err){
    console.log("error updating user free plan type =>", err);
    return null;
  }

};

export const meetingCall = async () => {
  try {
    await axios.post(`${awsExports.USERS_URL}`, {isMeeting: true});
  }
  catch(err) {
    console.log("error in meeting call service =>", err);
    return null;
  }
}
