import React, { useState, useRef, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import LoadingSpinner from "../LoadingSpinner";
import { CheckCircleIcon, ExclamationCircleIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const QuillEditor = ({value, onSave, saveStatus, isLoadingSaveEdit, saveButtonId, displaySaveButton = true, displayFindReplace = true, title = ""}) => {
  const [editorContent, setEditorContent] = useState(value);
  const [findText, setFindText] = useState('');
  const [replaceText, setReplaceText] = useState('');
  const [currentMatch, setCurrentMatch] = useState(0);
  const [matches, setMatches] = useState([]);
  const quillRef = useRef(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // Add this useEffect to handle highlighting when matches change
  useEffect(() => {
    if (matches.length > 0 && currentMatch >= 0) {
      highlightCurrentMatch(currentMatch);
    }
  }, [matches, currentMatch]);

  // Function to find all instances of text
  const findAllMatches = () => {
    if (!findText) return;
    
    const quill = quillRef.current.getEditor();
    
    // Store the current Delta to preserve formatting
    const currentDelta = quill.getContents();
    const text = quill.getText();
    const regex = new RegExp(findText, 'gi');
    const foundMatches = [];
    let match;
    
    while ((match = regex.exec(text)) !== null) {
      foundMatches.push({
        index: match.index,
        length: match[0].length
      });
    }

    // Restore the Delta to preserve formatting
    quill.setContents(currentDelta);
    
    setMatches(foundMatches);
    setCurrentMatch(foundMatches.length > 0 ? 0 : -1);
    
    if (foundMatches.length === 0) {
      alert('No matches found');
    }
  };

  // Function to highlight current match
  const highlightCurrentMatch = (matchIndex) => {
    if (!matches.length || matchIndex < 0) return;

    const quill = quillRef.current.getEditor();
    
    // Remove only the background color from previous highlights
    const textLength = quill.getText().length;
    for (let i = 0; i < textLength; i++) {
      const format = quill.getFormat(i, 1);
      if (format.background === 'yellow') {
        quill.formatText(i, 1, 'background', false);
      }
    }
    
    // Add new highlight while preserving other formatting
    const match = matches[matchIndex];
    const currentFormat = quill.getFormat(match.index, match.length);
    
    // Apply background color without altering other formats
    quill.formatText(match.index, match.length, {
      ...currentFormat,
      background: 'yellow'
    });
    
    // Scroll to highlighted text
    setTimeout(() => {
      const highlightedSpan = document.querySelector('.ql-editor [style*="background-color: yellow"]');
      if (highlightedSpan) {
        highlightedSpan.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        });
      }
    }, 0);
  };

  // Function to replace current match
  const replaceCurrent = () => {
    if (currentMatch < 0 || !matches.length) return;

    const quill = quillRef.current.getEditor();
    const match = matches[currentMatch];
    
    // Delete and insert using Quill operations
    quill.deleteText(match.index, match.length);
    quill.insertText(match.index, replaceText);
    
    // Calculate the difference in length
    const lengthDifference = replaceText.length - match.length;
    
    // Update remaining matches' indices
    const newMatches = matches.map((m, index) => {
      if (index > currentMatch) {
        return {
          ...m,
          index: m.index + lengthDifference
        };
      }
      return m;
    });
    
    newMatches.splice(currentMatch, 1);
    setMatches(newMatches);
    
    if (newMatches.length > 0) {
      const nextMatchIndex = currentMatch >= newMatches.length ? 
        newMatches.length - 1 : 
        currentMatch;
      setCurrentMatch(nextMatchIndex);
      highlightCurrentMatch(nextMatchIndex);
    } else {
      setCurrentMatch(-1);
    }
  };

  // Function to replace all matches
  const replaceAll = () => {
    if (!matches.length) return;

    const quill = quillRef.current.getEditor();
    
    // Replace all matches from last to first to maintain correct indices
    [...matches].reverse().forEach(match => {
      quill.deleteText(match.index, match.length);
      quill.insertText(match.index, replaceText);
    });
    
    setMatches([]);
    setCurrentMatch(-1);
  };

  // Function to navigate to next match
  const findNext = () => {
    if (!matches.length) return;
    
    const nextMatch = (currentMatch + 1) % matches.length;
    setCurrentMatch(nextMatch);
    highlightCurrentMatch(nextMatch);
  };

  // Function to navigate to previous match
  const findPrevious = () => {
    if (!matches.length) return;
    
    const prevMatch = currentMatch === 0 
      ? matches.length - 1 
      : currentMatch - 1;
    setCurrentMatch(prevMatch);
    highlightCurrentMatch(prevMatch);
  };

   // Add styles for the find/replace interface
   const styles = {
    findReplaceContainer: {
      marginBottom: '20px',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    inputGroup: {
      marginBottom: '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    input: {
      padding: '2px 5px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      fontSize: '12px',
    },
    button: {
      className: 'px-2.5 py-10 rounded-md border border-gray-300 bg-white cursor-pointer text-sm',
    },
    matchCount: {
      marginLeft: '10px',
      flexShrink: 0,
      color: 'rgb(67 56 202)',
      fontSize: '12px',
    },
  };

  const handleSave = () => {
    const quill = quillRef.current.getEditor();
    
    // Only remove background formatting (highlights)
    const delta = quill.getContents();
    const tempQuill = new Quill(document.createElement('div'));
    tempQuill.setContents(delta);
    
    // Remove only background formatting
    const length = tempQuill.getLength();
    tempQuill.formatText(0, length, 'background', false);
    
    // Get the HTML with all other formatting preserved
    const htmlVersion = tempQuill.root.innerHTML;
    
    // Create plain text version
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlVersion;
    
    const plainText = tempDiv.innerText
      .replace(/\s+/g, ' ')
      .replace(/Speaker [A-Z]/g, match => `\n${match}: `)
      .trim();

    onSave({
      editedHtml: htmlVersion,
      editedText: plainText
    });
  };

  // Add this near the top of the file with other constants
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }]
    ]
  };

  // Add this useEffect to properly handle content updates
  useEffect(() => {
    if (value !== editorContent) {
      setEditorContent(value);
    }
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    setEditorContent(content);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center pb-2">
      <span className="text-base font-bold text-indigo-600 pb-2">{title}</span>

      {displaySaveButton && <div className="flex justify-end items-end ">
      <div className="flex flex-shrink-0 gap-2">
  
        <button 
              id={saveButtonId}
              disabled={isLoadingSaveEdit}
              onClick={handleSave}
              className={`px-3 py-1 ${isLoadingSaveEdit ? 'bg-indigo-600 bg-opacity-55' : ' hover:bg-opacity-75'} flex-shrink-0 px-4 py-2 text-white bg-indigo-600 flex items-center justify-center rounded-full hover:opacity-45 text-sm`}
            >
              {!isLoadingSaveEdit && "Save Edits"}
              {isLoadingSaveEdit && <span className="animate-pulse"><LoadingSpinner /></span>}
            </button>
          
          {saveStatus && (
            <div className="flex items-center">
              {saveStatus === 'success' ? (
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
              ) : saveStatus === 'error' ? (
                <ExclamationCircleIcon className="w-6 h-6 text-red-500" />
              ) : null}
            </div>
          )}
      </div>

      </div>}

      </div>



      {displayFindReplace && <div className="">
        <button
          onClick={() => setIsSearchOpen(!isSearchOpen)}
          className="flex items-center gap-2 text-gray-700 hover:text-gray-900 text-xs mb-4"
        >
          <ChevronDownIcon 
            className={`w-4 h-4 transition-transform ${
              isSearchOpen ? 'transform rotate-180' : ''
            }`}
          />
          <span className="flex items-center justify-center"><MagnifyingGlassIcon className="w-4 h-4 inline mr-1" />Find and Replace</span>
        </button>

        {isSearchOpen && (
          <div style={styles.findReplaceContainer}>
            <div style={styles.inputGroup}>
              <input
                type="text"
                value={findText}
                onChange={(e) => setFindText(e.target.value)}
                placeholder="Find text..."
                style={styles.input}
              />
              <button 
                onClick={findAllMatches} 
                className="hover:bg-gray-100 px-2 py-0.5 rounded-md border border-gray-300 bg-white cursor-pointer text-xs"
              >
                Find
              </button>
              <button 
                onClick={findPrevious} 
                className="hover:bg-gray-100 px-2 py-0.5 rounded-md border border-gray-300 bg-white cursor-pointer text-xs"
                disabled={!matches.length}
              >
                Previous
              </button>
              <button 
                onClick={findNext} 
                className="hover:bg-gray-100 px-2 py-0.5 rounded-md border border-gray-300 bg-white cursor-pointer text-xs"
                disabled={!matches.length}
              >
                Next
              </button>
              <span style={styles.matchCount}>
                {matches.length > 0 
                  ? `${currentMatch + 1} of ${matches.length} matches` 
                  : 'No matches'}
              </span>
            </div>
            
            <div style={styles.inputGroup}>
              <input
                type="text"
                value={replaceText}
                onChange={(e) => setReplaceText(e.target.value)}
                placeholder="Replace with..."
                style={styles.input}
              />
              <button 
                onClick={replaceCurrent} 
                className="hover:bg-gray-100 px-2 py-0.5 rounded-md border border-gray-300 bg-white cursor-pointer text-xs"
                disabled={!matches.length}
              >
                Replace
              </button>
              <button 
                onClick={replaceAll} 
                className="hover:bg-gray-100 px-2 py-0.5 rounded-md border border-gray-300 bg-white cursor-pointer text-xs"
                disabled={!matches.length}
              >
                Replace All
              </button>
            </div>
          </div>
        )}
      </div>}

      <div className="flex-1 overflow-y-auto min-h-0">
        <ReactQuill 
          ref={quillRef} 
          value={editorContent} 
          onChange={handleChange}
          modules={modules}
          preserveWhitespace={true}
          className="rounded-lg"
        />
      </div>

      <style>
        {`
           .highlighted-text {
            background-color: yellow;
            padding: 2px;
            border-radius: 2px;
          }
          
          .ql-editor {
            min-height: 100%;
          }

          .ql-container {
            border-bottom-left-radius: 0.5rem; /* This matches rounded-lg */
            border-bottom-right-radius: 0.5rem; /* This matches rounded-lg */
          }
          
          .ql-toolbar {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
          }
        `}
      </style>
    </div>
  );
};

export default QuillEditor;
