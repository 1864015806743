import React, { Component } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

class ContactModal extends Component {
  render() {
    const { handleModalCloseFn } = this.props;
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-4/5 sm:w-1/2 rounded px-6 sm:px-12 pt-6 pb-12 shadow-lg border">
          <div className="flex justify-between items-center">
            <span />
            <XMarkIcon
              onClick={handleModalCloseFn}
              class="h-6 w-6 text-black/80 cursor-pointer hover:scale-110"
            />
          </div>
          <div class="py-8 font-bold text-lg text-center">Contact Us</div>

          <span>
            Please contact <b>amrit@helloxylo.com</b> for more information about our
            enterprise plan.
          </span>
        </div>
      </div>
    );
  }
}

export default ContactModal;