import React, { Component } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { withRouter } from "../withRouterHook";
class UpgradeModal extends Component {
  handleUpgradeClick = () => {
    const { history } = this.props;
    history.push("/upgrade-plan");
  };

  render() {
    const { handleModalCloseFn } = this.props;
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-1/2 rounded px-12 pt-6 pb-12 shadow-lg border">
          <div className="flex justify-between items-center">
            <span />
            <XMarkIcon
              onClick={handleModalCloseFn}
              class="h-6 w-6 text-black/80 cursor-pointer hover:scale-110"
            />
          </div>
          <div class="py-12 text-center text-lg font-bold">
            Upgrade your plan
          </div>
          <div class="py-4">
            You have reached the limit for meetings on your current plan. To
            create more meetings, upgrade your plan
            now.
          </div>
          <div class="py-8 flex w-full justify-center">
            <button
              class="rounded-full bg-indigo-600 px-4 py-2 text-white uppercase hover:scale-105 hover:opacity-70"
              onClick={this.handleUpgradeClick}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpgradeModal);
