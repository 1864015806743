import { BoltIcon } from "@heroicons/react/24/solid";

export const GOOGLE_MAPS_API_KEY = "AIzaSyB22lI4Id_npREyVoEldYT7y-rpyFUjt6o";

export const PAYMENT_LINK = {
  // STARTER: "https://buy.stripe.com/cN22bT96F0SR1hu3ce",
  STARTER: "https://buy.stripe.com/14k4k10A9597aS49AA",
  PRO: "https://buy.stripe.com/3cs17PciR7hf6BO6op"
};

export const PANEL_TITLE_STYLE =
  "uppercase tracking-wide text-2xl text-black items-center font-manrope";

export const PAGE_HEADER_STYLE =
  "pb-10 sm:pb-12 mt-10 sm:mt-20 px-4 lg:px-24 top-0 z-10 w-full flex flex-row justify-between items-center";

export const SURVEY_TYPE = {
  AUDIO: "Audio",
  NUMERICAL: "Numerical",
};

export const PRICE = {
  LOW: "low",
  HIGH: "high",
  ACCEPTABLE: "acceptable"
}

export const MENU_ITEMS = {
  // INSIGHTS: "Insights",
  VOICES: "Responses",
  SURVEY: "Recorder Details",
  UPLOADS: "Uploads",
  RECORDINGS: "Recordings",
  MEETINGS: "Meetings"
};

export const menuItemsArray = [
  // MENU_ITEMS.INSIGHTS,
  MENU_ITEMS.VOICES,
  MENU_ITEMS.SURVEY,
];

export const SELECTED_PLAN = {
  YEARLY: "yearly",
  MONTHLY: "monthly",
};
export const PLAN_TYPE = {
  FREE: "free",
  BUSINESS: "business",
};
export const PRICING_PAGE = {
  CONFIRM: "CONFIRM",
  PRICING: "PRICING",
  UPGRADE: "UPGRADE"
}

export const getContributionUrl = ({ surveyId }) => {
  return `https://helloxylo.com/respond/${surveyId}`;
};

export const RecordingWidget = () => {
  return (
    <div class="uppercase mt-2 text-black/60 text-sm flex flex-shrink-0 font-bold">
      <BoltIcon class="h-5 w-5 inline mr-2" />
      Tap to record
    </div>
  );
};

export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (
    typeof value === "string" ||
    Array.isArray(value) ||
    typeof value === "function"
  ) {
    return value.length === 0;
  }

  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
};

export const UserIcon = () => {
  return (
    <svg
      className="h-7 w-7 mt-1.5 text-black inline"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      fill="none"
      width="28px"
    >
      <path
        fill="currentColor"
        d="M18.5 12C18.5 13.0878 18.1774 14.1512 17.5731 15.0556C16.9687 15.9601 16.1098 16.6651 15.1048 17.0813C14.0998 17.4976 12.9939 17.6065 11.927 17.3943C10.8601 17.1821 9.88011 16.6583 9.11092 15.8891C8.34173 15.1199 7.81791 14.1399 7.60569 13.073C7.39347 12.0061 7.50239 10.9002 7.91867 9.89524C8.33495 8.89025 9.0399 8.03126 9.94437 7.42692C10.8488 6.82257 11.9122 6.5 13 6.5C14.4587 6.5 15.8576 7.07946 16.8891 8.11091C17.9205 9.14236 18.5 10.5413 18.5 12ZM26 13C26 15.5712 25.2376 18.0846 23.8091 20.2224C22.3807 22.3603 20.3503 24.0265 17.9749 25.0104C15.5995 25.9944 12.9856 26.2518 10.4638 25.7502C7.94208 25.2486 5.6257 24.0105 3.80762 22.1924C1.98953 20.3743 0.751405 18.0579 0.249797 15.5362C-0.251811 13.0144 0.0056327 10.4006 0.989572 8.02512C1.97351 5.64968 3.63975 3.61935 5.77759 2.1909C7.91543 0.762437 10.4288 0 13 0C16.4458 0.00660747 19.7486 1.37837 22.1851 3.81491C24.6216 6.25145 25.9934 9.55422 26 13ZM24 13C24.0007 11.2184 23.5686 9.46335 22.7409 7.88569C21.9133 6.30804 20.7148 4.95502 19.2486 3.943C17.7823 2.93098 16.0922 2.29023 14.3236 2.07586C12.555 1.86149 10.7607 2.07991 9.09518 2.71234C7.42964 3.34477 5.94262 4.37228 4.76202 5.70652C3.58141 7.04076 2.74255 8.64179 2.31756 10.3719C1.89257 12.1021 1.89418 13.9096 2.32225 15.6389C2.75032 17.3683 3.59203 18.9679 4.77501 20.3C5.58448 19.1388 6.62759 18.1596 7.83751 17.425C9.22669 18.7559 11.0762 19.4989 13 19.4989C14.9238 19.4989 16.7733 18.7559 18.1625 17.425C19.3724 18.1596 20.4155 19.1388 21.225 20.3C23.0158 18.2904 24.0037 15.6917 24 13Z"
      />
    </svg>
  );
};

export const ArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" fill="#000"><g><path d="m385.54 271.64-.8.45.8-.45ZM447.89 211.06c-18.76-27.31-44.82-59.56-66.31-83.65a9.18 9.18 0 0 0-5.38-3.7c-10.65-2.09-14.53 7.22-6.33 14.04 18.97 23.31 37.56 47.09 54.62 71.86-27.69-9-80.46-12.81-89.57-14-78.54-5.99-162.26 9.22-223.16 62.1-32.32 27.27-66.02 69.31-65.22 113.55-.02 7.28 17.74 8.59 19.2-5.66 7.56-72.25 95.56-133.37 162.44-146.43 55.03-12.22 112.14-8.65 167.2 1.5 23.18 4.25 22.56 5.37 29.51 7.79-17.27 11.16-35.79 20.45-53.52 30.85-6.49 5.38-28.25 9.44-21.03 20.84 10.16 11.23 24.87-4.35 35.21-8.51a2123.25 2123.25 0 0 0 33.44-19.16c10.18-7.17 31.93-15.13 34.5-27.99.37-5.08-2.96-9.39-5.57-13.44Z"></path></g></svg>
  )
}

export const INSIGHT_WIDGET_TITLE_STYLE =
  "text-black/80 tracking wider";

export const STAR_RATING_NUMBER_MAP = {
  "1StarCount": 1,
  "2StarCount": 2,
  "3StarCount": 3,
  "4StarCount": 4,
  "5StarCount": 5,
};

export const FILTER_KEY = {
  STAR_RATING: "starRating",
  SENTIMENT: "sentiment",
  SATISFACTION: "satisfaction",
  DISAPPOINTMENT: "disappointment",
  BUY_AGAIN: "buyAgain",
  MARKETABILITY: "marketability",
  REQUIRES_ACTION: "requiresAction",
};

export const SCORE_VALUES = {
  SENTIMENT: { POSITIVE: "positive", NEGATIVE: "negative", NEUTRAL: "neutral" },
  BUY_AGAIN: { YES: "yes", NO: "no", MAYBE: "maybe" },
};

// const MARKETABILITY_THRESHOLD = 7;
const SATISFACTION_THRESHOLD = 7;
const DISAPPOINTMENT_THRESHOLD = 7;

export const getFilterLabel = ({ filterKey, filterValue }) => {
  if (filterKey === FILTER_KEY.STAR_RATING) {
    return `Star Rating of ${filterValue} `;
  }
  if (filterKey === FILTER_KEY.SENTIMENT) {
    return `With ${filterValue} sentiment`;
  }
  if (filterKey === FILTER_KEY.DISAPPOINTMENT) {
    return filterValue < DISAPPOINTMENT_THRESHOLD
      ? `Did not express disappointment`
      : `Expressed disappointment`;
  }
  if (filterKey === FILTER_KEY.SATISFACTION) {
    return filterValue < SATISFACTION_THRESHOLD
      ? `Did not express satisfaction`
      : `Expressed satisfaction`;
  }
  if (filterKey === FILTER_KEY.BUY_AGAIN) {
    if (filterValue === "yes")
      return "Respondents who are likely to purchase again";
    if (filterValue === "no")
      return "Respondents who are unlikely likely to purchase again";
    if (filterValue === "maybe") return "Respondents who might purchase again";
  }
  if (filterKey === FILTER_KEY.MARKETABILITY) {
    return `Great quotes for marketing`;
  }
  if (filterKey === FILTER_KEY.REQUIRES_ACTION) {
    return `Surveys with actionable points`;
  }
};

export const ERROR_MESSAGES = {
  FETCH_RESPONSE:
    "Sorry, there was a problem getting your survey. Please contact support at amrit@helloxylo.com for help.",
  CREATE_SURVEY:
    "Sorry, there was a problem creating your survey. Please contact support at amrit@helloxylo.com for help.",
  EDIT_SURVEY:
    "Sorry, there was a problem editing your survey. Please contact support at amrit@helloxylo.com for help.",
  DASHBOARD_USER_DETAILS: 
  "Sorry, there was a problem displaying your dashboard and getting user details. Please contact support at amrit@helloxylo.com for help.",
  DASHBOARD_USER_INFORMATION: 
  "Sorry, there was a problem displaying your dashboard and getting user information. Please contact support at amrit@helloxylo.com for help."
};


export const NeutralFaceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <circle cx="12" cy="12" r="10" strokeLinecap="round" strokeLinejoin="round"/>
  <circle cx="9" cy="10" r="1" fill="currentColor"/>
  <circle cx="15" cy="10" r="1" fill="currentColor"/>
  <path strokeLinecap="round" strokeLinejoin="round" d="M8 15h8"/>
</svg>
);

// export const INTRO_TEMPLATE = ["Summary", "Background", "Financial Details", "Retirement Goals", "Financial Goals", "Motivations and Concerns", "Next Steps"];
// export const DISCOVERY_TEMPLATE = ["Summary", "Background", "Income", "Liquid Assets", "Illiquid Assets", "Insurances", "Liabilities", "Current Expenses", "Retirement Goals", "Financial Goals", "Investment Style", "Motivations and Concerns", "Next Steps"];