import React, { Component } from "react";
import { withRouter } from "../withRouterHook";

class ExpiredModal extends Component {
  handleUpgradeClick = () => {
    const { history } = this.props;
    history.push("/upgrade-plan");
  };

  render() {    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-1/2 rounded px-12 pt-6 pb-12 shadow-lg border">
          <div class="py-12 text-center text-lg font-bold">
            Your free trial has expired
          </div>
          <div class="py-4">
            Your free 7 day trial has now expired. We hope you enjoyed using Xylo. To keep using Xylo and to access many more features, upgrade your plan now.
          </div>
          <div class="py-8 flex w-full justify-center">
            <button
              class="rounded-full bg-indigo-900 px-4 py-2 text-white uppercase hover:scale-110 hover:opacity-70"
              onClick={this.handleUpgradeClick}
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ExpiredModal);
