import React, { Component } from "react";
import { withRouter } from "./withRouterHook";
import { withAuthenticator } from "@aws-amplify/ui-react";
import ContactModal from "./Modals/ContactModal";
import "../styles.css";
import {  PAYMENT_LINK, PRICING_PAGE } from "../shared";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

class UpgradePlan extends Component {
  state = { showContactModal: false };

  handleContactSales = () => {
    this.setState({ showContactModal: true });
  };

  handleModalClose = () => {
    this.setState({ showContactModal: false });
  };

  handleCloseButtonClick = () => {
    this.props.history.push("/dashboard/meetings");
  }

  handleStarterTierClick = () => {
    //Test
    // window.open("https://buy.stripe.com/test_28odTKfy92HBayI6op", "_blank");
    window.open(PAYMENT_LINK.STARTER, "_blank");
  }

  handleProTierClick = () => {
    //Test
    // window.open("https://buy.stripe.com/test_fZe3f699Lbe7cGQcMO", "_blank");
    window.open(PAYMENT_LINK.PRO, "_blank");
  }

  render() {
    const { showContactModal } = this.state;
    const type = PRICING_PAGE.UPGRADE;
    return (
        <div class="sm:h-screen">
          {type === PRICING_PAGE.UPGRADE && 
          <div class="flex justify-end px-8 xl:px-28 py-4 sm:py-12"> 
            <XMarkIcon
                  className="inline h-10 w-10 mr-4 cursor-pointer hover:scale-105 text-black/90"
                  onClick={this.handleCloseButtonClick}
                />
          </div>}
          <div class="pb-8 sm:pb-52">
            {showContactModal && (
              <ContactModal handleModalCloseFn={handleModalClose} />
            )}
            <div class={`${showContactModal ? "blur-xl" : ""} px-8 lg:px-28 xl:px-48`}>
              <div class="max-w-3xl mx-auto py-6 sm:py-12" >
                {type === PRICING_PAGE.CONFIRM && <div class="text-4xl text-center font-bold py-6 font-manrope">
                  Confirm the plan you would like to choose
                </div>}
                {type === PRICING_PAGE.UPGRADE && (
                <div class="text-4xl text-center font-bold py-6 font-manrope">
                  Choose the best plan to grow your{" "}
                  <span class="scaling-animation"> business</span>
                </div>)
                }
                {type === PRICING_PAGE.PRICING &&
                  <div>
                  <div class="text-black text-6xl text-center font-bold py-6 font-manrope">
                  Pricing
                </div>
                <div class="text-black/80 text-lg text-center">
                  Ready to save hours by automating notetaking in meetings? 
                </div>
               
                  </div>
                }
              </div>
              <div class="flex items-center justify-center">
                <div class={`grid gap-8 sm:grid-cols-1 md:grid-cols-2 ${type === PRICING_PAGE.UPGRADE ? 'lg:grid-cols-2' : 'lg:grid-cols-3'}`}>
                  {/* Free Trial Card */}
                  {type !== PRICING_PAGE.UPGRADE && (
                    <div class="w-full max-w-sm p-4 bg-white border rounded-lg shadow sm:p-8">
                      <h5 class="mb-4 text-xl font-medium text-gray-500">Free Trial</h5>
                      <div class="flex items-baseline text-gray-900">
                        <span class="text-3xl font-semibold">$</span>
                        <span class="text-5xl font-extrabold tracking-tight">0</span>
                        <span class="ml-1 text-xl font-normal text-gray-500">/month</span>
                      </div>
                      <p class="mt-4 text-gray-500">Best for trying out our AI meeting assistant.</p>
                      <button class="w-full mt-6 text-white bg-indigo-600 hover:bg-indigo-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={type === PRICING_PAGE.PRICING ? handlePricingPageTierClick : type === PRICING_PAGE.CONFIRM ? handleFreePlanButtonClick : this.handleStarterTierClick}>
                        Start Free Trial
                      </button>
                      <ul class="space-y-4 mt-8">
                        <div class="flex">
                          <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                          <span>3 meetings</span>
                        </div>
                        <div class="flex">
                          <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                          <span>AI Meeting Summaries</span>
                        </div>
                        <div class="flex">
                          <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                          <span>AI Key Client Information</span>
                        </div>
                      </ul>
                    </div>
                  )}

                  {/* Starter Card */}
                  <div class="w-full max-w-sm p-4 bg-white border rounded-lg shadow sm:p-8">
                    <h5 class="mb-4 text-xl font-medium text-gray-500">Starter</h5>
                    <div class="flex items-baseline text-gray-900">
                      <span class="text-3xl font-semibold">$</span>
                      <span class="text-5xl font-extrabold tracking-tight">95</span>
                      <span class="ml-1 text-xl font-normal text-gray-500">/month</span>
                    </div>
                    <p class="mt-4 text-gray-500">Best for solo professionals looking to save a few hours by automating note taking in meetings.</p>
                    <button class="w-full mt-6 text-white bg-indigo-600 hover:bg-opacity-70 font-medium rounded-lg text-sm px-5 py-2.5 text-center uppercase"
                      onClick={this.handleStarterTierClick}>
                      Get Starter
                    </button>
                    <ul class="space-y-4 mt-8">
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>15 meeting hours/month</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Meeting notes</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>Follow-up emails</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>Priority Support</span>
                      </div>
                    </ul>
                  </div>

                  {/* Pro Card */}
                  <div class="w-full max-w-sm p-4 bg-white border rounded-lg shadow sm:p-8">
                    <h5 class="mb-4 text-xl font-medium text-gray-500">Pro</h5>
                    <div class="flex items-baseline text-gray-900">
                      <span class="text-3xl font-semibold">$</span>
                      <span class="text-5xl font-extrabold tracking-tight">145</span>
                      <span class="ml-1 text-xl font-normal text-gray-500">/month</span>
                    </div>
                    <p class="mt-4 text-gray-500">Best for teams who have several meetings a week, looking to save several hours by automating note taking in meetings.</p>
                    <button class="w-full mt-6 text-white bg-indigo-600 hover:bg-opacity-70 font-medium rounded-lg text-sm px-5 py-2.5 text-center uppercase"
                      onClick={this.handleProTierClick}>
                      Get Pro
                    </button>
                    <ul class="space-y-4 mt-8">
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>30 meeting hours/month</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>AI Meeting notes</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>Follow-up emails</span>
                      </div>
                      <div class="flex">
                        <CheckIcon class="h-4 w-4 mr-4 flex-shrink-0" />
                        <span>VIP support</span>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
    );
  }
}

export default withAuthenticator(withRouter(UpgradePlan));
