import React, { Component } from "react";
import { isEmpty } from "../shared";
import { MOCK_EMAIL, MOCK_EMAIL_DISCOVERY } from "../utils";
import { EnvelopeIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import ColourLoadingSpinner from "./ColourLoadingSpinner";
import QuillEditor from "./QuillEditor/QuillEditor";

class FollowupEmail extends Component {



  render() {
    const { handleGenerateEmail, isLoadingEmail, isEmailError} = this.props;
    const { followupEmail } = this.props;
    // const followupEmail = MOCK_EMAIL_DISCOVERY;
    return(
      <div>
        <div className="flex flex-col items-center justify-center text-sm whitespace-pre-line">
          {isEmailError && <div className="text-sm pt-12"><div className="flex items-center">
                            <InformationCircleIcon className="flex-shrink-0 h-6 w-6 text-red-700 mr-2 inline" />
                            <p className="">Error generating email. Please retry.</p>
              </div></div>}
          {isEmpty(followupEmail) && 
          <div className="pt-12">
            {isLoadingEmail && <div className="flex flex-col items-center justify-center"><div className="animate-pulse text-indigo-600 text-opacity-55 pb-4">Generating Email...</div><ColourLoadingSpinner /></div>}
            {!isLoadingEmail && <button className={`rounded-md bg-indigo-600 text-white font-bold px-4 py-2 text-sm flex justify-center items-center hover:opacity-60`} onClick={() => handleGenerateEmail()}>
                      <span>Generate Email<EnvelopeIcon className="inline ml-2 h-4 w-4" /></span>
              </button>}
            </div>}
            {!isEmpty(followupEmail) && (
            <div className="h-[calc(100vh-theme(spacing.48))]">
              <QuillEditor 
                value={followupEmail} 
                isLoadingSaveEdit={false} 
                saveStatus="success" 
                displaySaveButton={false} 
                displayFindReplace={false}
              />
            </div>
          )}
        </div>
      
      </div>
    
    )
  }
}

export default FollowupEmail;
