import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import custom from "../images/custom.png";
import edits from "../images/edits.png";
import copy from "../images/copy.png";
import LandingPageHeader from "./LandingPageHeader.js";


class Updates extends React.Component {

  render() {

    return (
      <div>
       <LandingPageHeader />
       <div class="px-4 sm:px-20">   
        <div class="flex justify-between ">
          </div>
          <div class='px-4 sm:px-64 py-6 sm:py-12 text-sm sm:text-base'>
          <div class="font-manrope text-2xl sm:text-3xl text-center py-8">Xylo Updates 19/06/2024</div> 
          <div class=" pb-8">{"1) Add custom vocabulary to meetings. You can now add custom words/phrases to boost their transcription accuracy during a meeting. Simply add this list of words to the Custom Vocabulary section when creating a new meeting."}</div>
              <img class="h-[200px] sm:h-[300px]  border border-indigo-500 rounded-xl" src={custom} />
              <div class="py-8">{`2) Edit your transcript and save it. The edited transcript will be used to generate the AI meeting analysis.`}</div>
              <img class="h-[200px] sm:h-[300px] border border-indigo-500 rounded-xl" src={edits} />
              <div class="py-8">{`3) Copy the entire transcript and meeting summary notes`}</div>
              <img class="h-[300px] border border-indigo-500 rounded-xl" src={copy} />
 
          </div>
       
       
      </div>
      </div>
    );
  }
}

export default (Updates);
